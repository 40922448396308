import html2canvas from "html2canvas";
import fileDownload from "js-file-download";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { useConstructionExtensions } from "@/hooks";
import { RootState } from "@/reducers/types";
import { Button } from "@/sx-layout/common/Button";
import { downloadPlotMap } from "@/sx-layout/components/plotmap/components/fileExport/actions";
import { DownloadFileParams } from "@/sx-layout/components/plotmap/components/fileExport/models";

type Props = {
  loggedInUserCompanyId?: number;
  scale: number;
  mapRatio: number;
};

const FileExport: React.FC<Props> = ({ loggedInUserCompanyId, scale, mapRatio }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [queryParams, setQueryParams] = useReducer(
    (prev: DownloadFileParams, next: Partial<DownloadFileParams>) => {
      return {
        ...prev,
        ...next,
      };
    },
    {
      filetype: 1,
      format: "excel",
      plot_plan_id: 0,
      layout_date: new Date(),
    }
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const plotPlanId = useSelector<RootState, string>((state) => state.plotmap.plotPlanId);
  const layoutDate = useSelector<RootState, any>((state) => state.plotmap.layoutDate);

  const { extension } = useConstructionExtensions(13);

  const canExportRequest = useMemo(() => {
    if (!extension) {
      return false;
    }
    const companyIds: number[] = extension.config;

    return companyIds.includes(loggedInUserCompanyId);
  }, [extension, loggedInUserCompanyId]);

  useEffect(() => {
    setQueryParams({ plot_plan_id: parseInt(plotPlanId) ?? 0 });
  }, [plotPlanId]);

  useEffect(() => {
    setQueryParams({ layout_date: layoutDate });
  }, [layoutDate]);

  const onOpenDropdown = () => {
    setIsDropdownOpen(true);
  };

  const onCloseDropdown = () => {
    setIsDropdownOpen(false);
  };

  const onDownloadMap = () => {
    onDownloadPlotMap({ ...queryParams, filetype: 1 });
  };

  const onDownloadRequest = () => {
    onDownloadPlotMap({ ...queryParams, filetype: 2 });
  };

  const onDownloadPlotMap = async (params: DownloadFileParams) => {
    const element = document.querySelector(".react-transform-component") as HTMLElement;
    if (!element) return;
    // HTMLElementからCanvasに変換
    // scaleとmapRatioの計算で元の画像のサイズにできる
    // 動作確認用: const { width, height } = await createImageBitmap(mapImageBlob);
    const canvas = await html2canvas(element, { scale: scale * (1 / mapRatio) });
    canvas.toBlob((blob: Blob | null) => {
      if (blob) {
        const fd = new FormData();
        fd.append("layout_image", blob);

        dispatch(
          downloadPlotMap(
            {
              ...params,
              formData: fd,
            },
            {
              onBegin: () => {
                setIsDownloading(true);
              },
              onSuccess: (blob, filename) => {
                setIsDownloading(false);
                fileDownload(blob, filename);
              },
              onError: () => {
                setIsDownloading(false);
              },
            }
          )
        );
      } else {
        dispatch(
          actions.app.showAlert(
            t("error"),
            [
              t("upload_failed1") +
                `${params.filetype === 1 ? t("machine_layout_diagram") : t("machine_request_form")}` +
                t("upload_failed2"),
            ],
            () => dispatch(actions.app.hideAlert())
          )
        );
      }
    }, "image/png");
  };

  return (
    <React.Fragment>
      <div className="btn-dropdown-area !z-auto" onMouseLeave={onCloseDropdown}>
        <Button className="flex items-center justify-center btn-dropdown" onClick={onOpenDropdown}>
          <img src="./img/icon-download.svg" className="mr-2 w-[16px]" />
          {t("output_file")}
        </Button>
        <div className={`${isDownloading ? "loading-small loading-small-download" : ""}`} />
        {isDropdownOpen ? (
          <ul className="dropdown-menu z-50">
            <li onClick={onDownloadMap}>
              <span>
                <img src="./img/icon_xls.svg" alt="download-position-map" className="icon-file" />
              </span>
              <span>{t("machine_layout_diagram")}</span>
            </li>
            {canExportRequest ? (
              <li onClick={onDownloadRequest}>
                <span>
                  <img src="./img/icon_xls.svg" alt="download-position-map" className="icon-file" />
                </span>
                <span>{t("machine_request_form")}</span>
              </li>
            ) : null}
          </ul>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default FileExport;
