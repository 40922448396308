import { KonvaEventObject } from "konva/lib/Node";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Layer, Stage } from "react-konva";
import { useDispatch, useSelector } from "react-redux";
import { useKeyPressEvent } from "react-use";

import { FreeDrawArrow, FreeDrawText, Size } from "../models";

import actions from "@/actions";
import { getUserId } from "@/lib/common";
import { RootState } from "@/reducers/types";
import { ArrowObject } from "@/sx-layout/components/plotmap/components/freeDraw/components/ArrowObject";
import { KeepOutAreaPolygonObject } from "@/sx-layout/components/plotmap/components/freeDraw/components/KeepOutAreaPolygonObject";
import { TextObject } from "@/sx-layout/components/plotmap/components/freeDraw/components/TextObject";
import { KeepOutAreaPolygon, Rectangle, UserRole, UserRoles } from "@/sx-layout/components/plotmap/models";
import { useTerm } from "@/sx-layout/hooks";
import { User } from "@/sx-layout/models";

type FreeDrawRect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

type FreeDrawMainProps = {
  mainScreenElem: HTMLDivElement;
  mapRatio: number;
  isModalOpen: boolean;
  // 矢印
  arrows: FreeDrawArrow[];
  selectedArrow?: FreeDrawArrow;
  onClickArrow: (arrow: FreeDrawArrow) => void;
  onMoveArrow: (arrow: FreeDrawArrow) => void;
  onSelectArrowEnd: () => void;

  // テキスト
  texts: FreeDrawText[];
  selectedText?: FreeDrawText;
  onClickText: (text: FreeDrawText) => void;
  onMoveText: (text: FreeDrawText) => void;
  onSelectTextEnd: () => void;

  // 立入禁止エリア (多角形)
  keepOutAreaPolygon: KeepOutAreaPolygon[];
  selectedArea?: KeepOutAreaPolygon;
  onClickArea: (area: KeepOutAreaPolygon, rect: Rectangle) => void;
  onMoveArea: (area: KeepOutAreaPolygon) => void;
  onDragAreaStart: () => void;
  onDragAreaEnd: (rect: Rectangle) => void;
  onSelectAreaEnd: () => void;

  disabled?: boolean;
};

export const FreeDrawMain: React.FC<FreeDrawMainProps> = (props) => {
  const layoutDate = useSelector<RootState, Date>((state) => state.plotmap.layoutDate);
  const { isPast } = useTerm(layoutDate);
  const dispatch = useDispatch();

  const userRole = useSelector<any, UserRoles>((state) => state.layoutApp.layoutMasters.user_role);
  const users = useSelector<any, User[]>((state) => state.layoutApp.layoutMasters.users);
  const loggedInUserCompanyId = useMemo(() => users.find((user) => user.user_id === getUserId())?.company_id, [users]);

  const [stageSize, setStageSize] = useState<Size>({ width: 0, height: 0 });

  useEffect(() => {
    if (!props.mainScreenElem) return;
    // 拡大/縮小されたらkonvaのキャンバスサイズも変える
    setStageSize({ width: props.mainScreenElem.clientWidth, height: props.mainScreenElem.clientHeight });
  }, [props.mainScreenElem?.clientWidth, props.mainScreenElem?.clientHeight]);

  // 矢印・テキスト共通
  // Escapeキーで選択状態を解除する
  useKeyPressEvent("Escape", () => {
    props.onSelectArrowEnd();
    props.onSelectTextEnd();
    props.onSelectAreaEnd();
  });

  // マップ外をクリックしたときに選択状態を解除する
  const handleClick = useCallback(
    (event) => {
      const paths = event.composedPath();
      const freeDrawPaths = ["free-draw-main", "free-draw-copy-modal"];
      // 矢印
      const arrowPaths = [...freeDrawPaths, "arrow-tool", "arrow-edit-modal", "arrow-delete-modal"];
      if (
        !!props.selectedArrow &&
        !props.isModalOpen &&
        !paths.some((path: HTMLElement) => arrowPaths.includes(path.id))
      ) {
        props.onSelectArrowEnd();
      }
      // テキスト
      const textPaths = [...freeDrawPaths, "text-tool", "text-edit-modal", "text-delete-modal"];
      if (
        !!props.selectedText &&
        !props.isModalOpen &&
        !paths.some((path: HTMLElement) => textPaths.includes(path.id))
      ) {
        props.onSelectTextEnd();
      }
      // 立入禁止エリア
      const areaPaths = [...freeDrawPaths, "keep-out-areas-editor-modal", "custom-area-delete-modal"];
      if (
        !!props.selectedArea &&
        !props.isModalOpen &&
        !paths.some((path: HTMLElement) => areaPaths.includes(path.id))
      ) {
        props.onSelectAreaEnd();
      }
    },
    [props.selectedArrow, props.selectedText, props.selectedArea, props.isModalOpen]
  );
  useEffect(() => {
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  // 矢印
  // 日付が変わったら選択状態を解除する
  useEffect(() => {
    const layoutDateStr = moment(layoutDate).format("YYYY-MM-DD").toString();
    if (props.selectedArrow?.layout_date && props.selectedArrow.layout_date !== layoutDateStr) {
      props.onSelectArrowEnd();
    }
  }, [props.selectedArrow?.layout_date, layoutDate]);

  // イベント
  const dragStart = useRef<number[]>();
  const handleArrowDragStart = useCallback(
    (e) => {
      dragStart.current = [e.target.x() / props.mapRatio, e.target.y() / props.mapRatio];
    },
    [props.mapRatio]
  );

  const handleArrowDragMove = useCallback(
    (e) => {
      const margin = 5;
      const left = e.target.x();
      const right = left + e.target.width();
      const top = e.target.y();
      const bottom = top + e.target.height();
      if (left < margin) {
        e.target.x(margin);
      }
      if (stageSize.width - margin < right) {
        e.target.x(stageSize.width - e.target.width() - margin);
      }
      if (top < margin) {
        e.target.y(margin);
      }
      if (stageSize.height - margin < bottom) {
        e.target.y(stageSize.height - e.target.height() - margin);
      }
    },
    [stageSize.height, stageSize.width]
  );

  const updateArrowCoordinates = useCallback(
    (coords: [number, number][]) => {
      dispatch(
        actions.arrows.updateArrowCoordinates({
          input: {
            arrow_id: props.selectedArrow.arrow_id,
            coords,
            timestamp: {
              update_date: props.selectedArrow.timestamp?.update_date,
            },
          },
          onSuccess: (arrow) => props.onMoveArrow(arrow),
        })
      );
    },
    [dispatch, props]
  );
  const handleArrowDragEnd = useCallback(
    (e) => {
      const diff = [
        e.target.x() / props.mapRatio - dragStart.current[0],
        e.target.y() / props.mapRatio - dragStart.current[1],
      ];
      updateArrowCoordinates(props.selectedArrow.coords.map((p) => [p[0] + diff[0], p[1] + diff[1]]));
      dragStart.current = [0, 0];
    },
    [props.selectedArrow, props.mapRatio, updateArrowCoordinates]
  );

  // テキスト
  // 日付が変わったら選択状態を解除する
  useEffect(() => {
    const layoutDateStr = moment(layoutDate).format("YYYY-MM-DD").toString();
    if (props.selectedText?.layout_date && props.selectedText.layout_date !== layoutDateStr) {
      props.onSelectTextEnd();
    }
  }, [props.selectedText?.layout_date, layoutDate]);

  // 選択しているテキスト以外の場所がクリックされたら選択状態を解除する
  const [selectedArrowRect, setSelectedArrowRect] = useState<FreeDrawRect>(null);
  const [selectedTextRect, setSelectedTextRect] = useState<FreeDrawRect>(null);
  const [selectedAreaRect, setSelectedAreaRect] = useState<FreeDrawRect>(null);
  const handleMouseDown = useCallback(
    (e: KonvaEventObject<MouseEvent>) => {
      e.target.preventDefault();
      if (selectedArrowRect) {
        const { x, y, width, height } = selectedArrowRect;
        if (e.evt.x < x || x + width < e.evt.x || e.evt.y < y || y + height < e.evt.y) {
          props.onSelectArrowEnd();
          setSelectedArrowRect(null);
        }
      }
      if (selectedTextRect) {
        const { x, y, width, height } = selectedTextRect;
        if (e.evt.x < x || x + width < e.evt.x || e.evt.y < y || y + height < e.evt.y) {
          props.onSelectTextEnd();
          setSelectedTextRect(null);
        }
      }
      if (selectedAreaRect) {
        const { x, y, width, height } = selectedAreaRect;
        if (e.evt.x < x || x + width < e.evt.x || e.evt.y < y || y + height < e.evt.y) {
          props.onSelectAreaEnd();
          setSelectedAreaRect(null);
        }
      }
    },
    [selectedArrowRect, selectedTextRect, selectedAreaRect]
  );

  const handleTextDragStart = useCallback(
    (e) => {
      dragStart.current = [e.target.x() / props.mapRatio, e.target.y() / props.mapRatio];
    },
    [props.mapRatio]
  );

  const handleTextDragMove = useCallback(
    (e) => {
      const margin = 3;
      const left = e.target.x();
      const right = left + e.target.width();
      const top = e.target.y();
      const bottom = top + e.target.height();
      if (left < margin) {
        e.target.x(margin);
      }
      if (stageSize.width - margin < right) {
        e.target.x(stageSize.width - e.target.width() - margin);
      }
      if (top < margin) {
        e.target.y(margin);
      }
      if (stageSize.height - margin < bottom) {
        e.target.y(stageSize.height - e.target.height() - margin);
      }
    },
    [stageSize.height, stageSize.width]
  );

  const updateTextCoordinate = useCallback(
    (coord: [number, number]) => {
      dispatch(
        actions.texts.updateTextCoordinate({
          input: {
            freetext_id: props.selectedText.freetext_id,
            x: coord[0],
            y: coord[1],
            timestamp: {
              update_date: props.selectedText.timestamp?.update_date,
            },
          },
          onSuccess: (text: FreeDrawText) => props.onMoveText(text),
        })
      );
    },
    [dispatch, props]
  );
  const handleTextDragEnd = useCallback(
    (e) => {
      const diff = [
        e.target.x() / props.mapRatio - dragStart.current[0],
        e.target.y() / props.mapRatio - dragStart.current[1],
      ];
      updateTextCoordinate([props.selectedText.x + diff[0], props.selectedText.y + diff[1]]);
      dragStart.current = undefined;
    },
    [props.selectedText, props.mapRatio, updateTextCoordinate]
  );

  // 立入禁止エリア // TODO arrowと同じものを使うこと
  const handleAreaDragStart = useCallback(
    (e) => {
      props.onDragAreaStart();
      dragStart.current = [e.target.x() / props.mapRatio, e.target.y() / props.mapRatio];
    },
    [props.mapRatio]
  );

  const handleAreaDragMove = useCallback(
    (e) => {
      const margin = 5;
      const left = e.target.x();
      const right = left + e.target.width();
      const top = e.target.y();
      const bottom = top + e.target.height();
      if (left < margin) {
        e.target.x(margin);
      }
      if (stageSize.width - margin < right) {
        e.target.x(stageSize.width - e.target.width() - margin);
      }
      if (top < margin) {
        e.target.y(margin);
      }
      if (stageSize.height - margin < bottom) {
        e.target.y(stageSize.height - e.target.height() - margin);
      }
    },
    [stageSize.height, stageSize.width, props.mapRatio, props.selectedArea]
  );

  const updateAreaCoordinates = useCallback(
    (coords: [number, number][]) => {
      const updated = {
        ...props.selectedArea,
        coords,
      };
      dispatch(
        actions.keepOutAreas.updateKeepOutAreaPolygonCoordinates(updated, (response) =>
          props.onMoveArea({
            ...props.selectedArea,
            coords,
            timestamp: response.timestamp,
          })
        )
      );
    },
    [dispatch, props.selectedArea]
  );
  const handleAreaDragEnd = useCallback(
    (e) => {
      const node = e.currentTarget;
      props.onDragAreaEnd({
        x: node.x(),
        y: node.y(),
        width: node.width(),
        height: node.height(),
      });
      const diff: [number, number] = [
        e.target.x() / props.mapRatio - dragStart.current[0],
        e.target.y() / props.mapRatio - dragStart.current[1],
      ];
      updateAreaCoordinates(props.selectedArea.coords.map((p) => [p[0] + diff[0], p[1] + diff[1]]));
      dragStart.current = [0, 0];
    },
    [props.selectedArea, props.mapRatio, updateAreaCoordinates]
  );

  if (stageSize.width === 0) return <></>;

  return (
    <Stage
      id="free-draw-main"
      {...stageSize}
      onMouseDown={handleMouseDown}
      onContextMenu={(e) => e.evt.preventDefault()}
    >
      <Layer>
        {props.arrows.map((arrow) => (
          <ArrowObject
            id={`free-draw-arrow-${arrow.arrow_id}`}
            key={`free-draw-arrow-${arrow.arrow_id}`}
            points={[]}
            freeDrawArrow={arrow}
            selected={props.selectedArrow?.arrow_id === arrow.arrow_id}
            mapRatio={props.mapRatio}
            setSelectedRect={setSelectedArrowRect}
            draggable={arrow.arrow_id === props.selectedArrow?.arrow_id && !isPast}
            onClick={(evt) => {
              evt.currentTarget.setZIndex((props.texts.length || 1) - 1);
              if (
                !props.disabled &&
                (userRole === UserRole.MASTER_ROLE ||
                  (userRole === UserRole.USER_ROLE && arrow.company_id === loggedInUserCompanyId))
              ) {
                props.onClickArrow(arrow);
              }
            }}
            onMouseDown={(e) => {
              e.cancelBubble = true;
            }}
            onDragStart={handleArrowDragStart}
            onDragMove={handleArrowDragMove}
            onDragEnd={handleArrowDragEnd}
          />
        ))}
        {props.texts.map((text) => (
          <TextObject
            id={`free-draw-text-${text.freetext_id}`}
            key={`free-draw-text-${text.freetext_id}`}
            freeDrawText={text}
            selected={props.selectedText?.freetext_id === text.freetext_id}
            mapRatio={props.mapRatio}
            setSelectedRect={setSelectedTextRect}
            draggable={text.freetext_id === props.selectedText?.freetext_id && !isPast}
            x={text.x}
            y={text.y}
            onClick={(evt) => {
              evt.currentTarget.moveToTop();
              if (
                !props.disabled &&
                (userRole === UserRole.MASTER_ROLE ||
                  (userRole === UserRole.USER_ROLE && text.company_id === loggedInUserCompanyId))
              ) {
                props.onClickText(text);
              }
            }}
            onMouseDown={(e) => {
              e.cancelBubble = true;
            }}
            onDragStart={handleTextDragStart}
            onDragMove={handleTextDragMove}
            onDragEnd={handleTextDragEnd}
          />
        ))}
        {props.keepOutAreaPolygon.map((area) => (
          <KeepOutAreaPolygonObject
            id={`free-draw-area-${area.keepout_area_id}`}
            key={`free-draw-area-${area.keepout_area_id}`}
            keepOutArea={area}
            selected={props.selectedArea?.keepout_area_id === area.keepout_area_id}
            mapRatio={props.mapRatio}
            setSelectedRect={setSelectedAreaRect}
            draggable={area.keepout_area_id === props.selectedArea?.keepout_area_id && !isPast}
            onClick={(evt) => {
              evt.currentTarget.moveToTop();
              if (
                !props.disabled &&
                (userRole === UserRole.MASTER_ROLE ||
                  (userRole === UserRole.USER_ROLE && area.company_id === loggedInUserCompanyId))
              ) {
                const node = evt.currentTarget;
                props.onClickArea(area, {
                  x: node.x(),
                  y: node.y(),
                  width: node.width(),
                  height: node.height(),
                });
              }
            }}
            onMouseDown={(e) => {
              e.cancelBubble = true;
            }}
            onDragStart={handleAreaDragStart}
            onDragMove={handleAreaDragMove}
            onDragEnd={handleAreaDragEnd}
          />
        ))}
      </Layer>
    </Stage>
  );
};
