import { useCallback, useState } from "react";

import { KeepOutAreaPolygon, Rectangle } from "@/sx-layout/components/plotmap/models";

export const useSelectedArea = () => {
  const [freeDrawArea, setFreeDrawArea] = useState<KeepOutAreaPolygon>();
  const [rect, setRect] = useState<Rectangle>();
  const [dragging, setDragging] = useState(false);

  const start = useCallback((area: KeepOutAreaPolygon, rect: Rectangle) => {
    setFreeDrawArea(area);
    setRect(rect);
  }, []);

  const end = useCallback(() => {
    setFreeDrawArea(null);
    setRect(null);
  }, []);

  const changeCoords = useCallback(
    (area: KeepOutAreaPolygon) => {
      setFreeDrawArea({
        ...freeDrawArea,
        coords: area.coords,
        timestamp: { ...area.timestamp, update_date: area.timestamp.update_date },
      });
    },
    [freeDrawArea]
  );

  const startDrag = () => {
    setDragging(true);
  };

  const endDrag = (rect: Rectangle) => {
    setRect(rect);
    setDragging(false);
  };

  const centerX = useCallback((): number => {
    if (!rect) return 0;
    return rect.x + rect.width / 2;
  }, [rect]);

  return { freeDrawArea, start, end, changeCoords, rect, centerX, startDrag, endDrag, dragging };
};
