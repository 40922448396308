import moment from "moment/moment";
import React, { forwardRef, useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import ChargeSelect from "@/components/common/ChargeSelect";
import MasterSelect from "@/components/common/Select";
import { ConstructionMasters } from "@/models/masters";
import { ScheduleOptionGroup } from "@/models/scheduleChiba";

type Props = {
  masters: ConstructionMasters;
  scheduleDate?: string | Date | null;
  areaIds: number[];
  deviceIds: number[];
  categoryIds: number[];
  facilityManagementIds: number[];
  constructionManagementIds: number[];
  primaryChargeIds: number[];
  otherIds: number[];
  companyIds: number[];
  userIds: number[];
  groupIds: number[];
  field1Ids: number[];
  approveIds: number[];
  checkpoint1Id: number;
  checkpoint2Id: number;
  checkpoint3Id: number;
  checkpoint4Id: number;
  checkpoint5Id: number;
  checkpoint6Id: number;
  filter: number;
  requestUserId: number;
  field5Ids: number;
  onChangeScheduleDate: (d: Date) => void;
  onChangeArea: (v: number[]) => void;
  onChangeDevice: (v: number[]) => void;
  onChangeCategory: (v: number[]) => void;
  onChangeFacilityManagement: (v: number[]) => void;
  onChangeConstructionManagement: (v: number[]) => void;
  onChangePrimaryCharge: (v: number[]) => void;
  onChangeOther: (v: number[]) => void;
  onChangeCompany: (v: number[]) => void;
  onChangeUser: (v: number[]) => void;
  onChangeGroup: (v: number[]) => void;
  onChangeField1: (v: number[]) => void;
  onChangeApprove: (v: number[]) => void;
  onChangeCheckpoint1: (v: number) => void;
  onChangeCheckpoint2: (v: number) => void;
  onChangeCheckpoint3: (v: number) => void;
  onChangeCheckpoint4: (v: number) => void;
  onChangeCheckpoint5: (v: number) => void;
  onChangeCheckpoint6: (v: number) => void;
  onChangeFilter: (v?: number) => void;
  onChangeRequestUser: (v: number) => void;
  onChangeField5: (v: number) => void;
  onClear: () => void;
  onSearch: () => void;
  searchConditionOptions: ScheduleOptionGroup[];
};

const COLUMN_WIDTH_1 = 270;
const COLUMN_WIDTH_2 = 270;
const COLUMN_WIDTH_3 = 310;
const COLUMN_WIDTH_4 = 270;
const OPTION_WITH_NAME = -1;
const OPTION_WITHOUT_NAME = -2;

export const ScheduleChibaSearchBox = forwardRef<HTMLDivElement, Props>(
  (
    {
      masters,
      scheduleDate,
      areaIds,
      deviceIds,
      categoryIds,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyIds,
      userIds,
      groupIds,
      field1Ids,
      approveIds,
      checkpoint1Id,
      checkpoint2Id,
      checkpoint3Id,
      checkpoint4Id,
      checkpoint5Id,
      checkpoint6Id,
      filter,
      requestUserId,
      field5Ids,
      onChangeScheduleDate,
      onChangeArea,
      onChangeDevice,
      onChangeCategory,
      onChangeFacilityManagement,
      onChangeConstructionManagement,
      onChangePrimaryCharge,
      onChangeOther,
      onChangeCompany,
      onChangeUser,
      onChangeGroup,
      onChangeField1,
      onChangeApprove,
      onChangeCheckpoint1,
      onChangeCheckpoint2,
      onChangeCheckpoint3,
      onChangeCheckpoint4,
      onChangeCheckpoint5,
      onChangeCheckpoint6,
      onChangeFilter,
      onChangeRequestUser,
      onChangeField5,
      onClear,
      onSearch,
      searchConditionOptions,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const convertOptions = useCallback(
      (target: string): { id: number; name: string; company_id?: string }[] => {
        return (
          searchConditionOptions
            .find((g) => g.target === target)
            ?.options.map((o) => ({ id: parseInt(o.id), name: o.name, company_id: o.company_id })) ?? []
        );
      },
      [searchConditionOptions]
    );

    // 検索条件の項目毎の選択肢
    const companyOptions = useMemo(() => convertOptions("company_id"), [searchConditionOptions]);
    const field1Options = useMemo(() => {
      const opts = convertOptions("field1");
      if (field1Ids.some((id) => id === OPTION_WITH_NAME || id === OPTION_WITHOUT_NAME)) {
        return opts.filter((o) => field1Ids.includes(o.id));
      }
      return opts;
    }, [searchConditionOptions, field1Ids]);
    const approveOptions = useMemo(() => {
      const opts = convertOptions("approve_user_id");
      if (approveIds.some((id) => id === OPTION_WITH_NAME || id === OPTION_WITHOUT_NAME)) {
        return opts.filter((o) => approveIds.includes(o.id));
      }
      return opts;
    }, [searchConditionOptions, approveIds]);
    const chargeOptions = useMemo(() => {
      const userListIds = convertOptions("user_id");
      const groupListIds = convertOptions("group_id");

      return {
        users: userListIds
          .filter((user) => {
            return companyIds.includes(parseInt(user.company_id));
          })
          .map((user) => {
            return { user_id: user.id, company_id: parseInt(user.company_id), user_name: user.name };
          }),
        groups: groupListIds
          .filter((group) => {
            return companyIds.includes(parseInt(group.company_id));
          })
          .map((group) => {
            return { group_id: group.id, company_id: parseInt(group.company_id), group_name: group.name };
          }),
      };
    }, [searchConditionOptions, companyIds]);
    const checkpoint1Options = useMemo(() => convertOptions("checkpoint1"), [searchConditionOptions]);
    const checkpoint2Options = useMemo(() => convertOptions("checkpoint2"), [searchConditionOptions]);
    const checkpoint3Options = useMemo(() => convertOptions("checkpoint3"), [searchConditionOptions]);
    const checkpoint4Options = useMemo(() => convertOptions("checkpoint4"), [searchConditionOptions]);
    const checkpoint5Options = useMemo(() => convertOptions("checkpoint5"), [searchConditionOptions]);
    const checkpoint6Options = useMemo(() => convertOptions("checkpoint6"), [searchConditionOptions]);
    const requestUserIdOptions = useMemo(() => convertOptions("request_user_id"), [searchConditionOptions]);
    const field5Options = useMemo(() => convertOptions("field5"), [searchConditionOptions]);

    const handleChangeCompany = (ids: number[]) => {
      if (ids.length > 0) {
        onChangeUser(
          chargeOptions.users
            .filter((user) => userIds.includes(user.user_id) && ids.includes(user.company_id))
            .map((user) => user.user_id)
        );
        onChangeGroup(
          chargeOptions.groups
            .filter((group) => userIds.includes(group.group_id) && ids.includes(group.company_id))
            .map((group) => group.group_id)
        );
      }
      onChangeCompany(ids);
    };

    const handleChangeApprove = (ids: number[]) => {
      if (ids.includes(OPTION_WITH_NAME) || ids.includes(OPTION_WITHOUT_NAME)) {
        onChangeApprove(ids.filter((id) => id === OPTION_WITH_NAME || id === OPTION_WITHOUT_NAME));
      } else {
        onChangeApprove(ids);
      }
    };

    const handleChangeField1 = (ids: number[]) => {
      if (ids.includes(OPTION_WITH_NAME) || ids.includes(OPTION_WITHOUT_NAME)) {
        onChangeField1(ids.filter((id) => id === OPTION_WITH_NAME || id === OPTION_WITHOUT_NAME));
      } else {
        onChangeField1(ids);
      }
    };

    return (
      <div className="search-box" ref={ref}>
        <div className="form-row">
          {/* 予定日 */}
          <div className="form-group scheduled-date" style={{ width: `${COLUMN_WIDTH_1}px` }}>
            <span className="form-label">{t("scheduled_date")}</span>
            {/* TODO 日付選択後、直接入力&focusで表示上の日付が入力値のままになるreact-datepickerの挙動がある */}
            <DatePicker
              wrapperClassName="w-170"
              selected={!scheduleDate ? null : moment(scheduleDate, "YYYY/MM/DD").toDate()}
              dateFormat="yyyy/MM/dd"
              locale={t("calender_locale")}
              onChange={onChangeScheduleDate}
            />
          </div>
          {/* エリア */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_2}px` }}>
            <span className="form-label">{t("area")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                prefix="area"
                isMulti={true}
                options={masters?.areas ?? []}
                value={areaIds}
                onChange={onChangeArea}
              />
            </div>
          </div>
          {/* 装置 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_3}px` }}>
            <span className="form-label">{t("device")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                prefix="device"
                isMulti={true}
                options={masters?.devices ?? []}
                value={deviceIds}
                onChange={onChangeDevice}
              />
            </div>
          </div>
          {/* 機器 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_4}px` }}>
            <span className="form-label">{t("machines_category")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                prefix="category"
                isMulti={true}
                options={masters?.categories ?? []}
                value={categoryIds}
                onChange={onChangeCategory}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          {/* 設備管理 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_1}px` }}>
            <span className="form-label">{t("facility_management")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                prefix="facility_management"
                isMulti={true}
                options={masters?.facility_managements ?? []}
                value={facilityManagementIds}
                onChange={onChangeFacilityManagement}
              />
            </div>
          </div>
          {/* 工事管理 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_2}px` }}>
            <span className="form-label">{t("construction_management")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                prefix="construction_management"
                isMulti={true}
                options={masters?.construction_managements ?? []}
                value={constructionManagementIds}
                onChange={onChangeConstructionManagement}
              />
            </div>
          </div>
          {/* NOTE: 2024年末に予定されている「検索条件の折りたたみ」機能実装時に復活する予定 */}
          {/* 元請 */}
          <div className="form-group hidden" style={{ width: `${COLUMN_WIDTH_3}px` }}>
            <span className="form-label">{t("primary_charge")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                prefix="primary_charge"
                isMulti={true}
                options={masters?.primary_charges ?? []}
                value={primaryChargeIds}
                onChange={onChangePrimaryCharge}
              />
            </div>
          </div>
          {/* その他 */}
          <div className="form-group hidden" style={{ width: `${COLUMN_WIDTH_4}px` }}>
            <span className="form-label">{t("other")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                prefix="other"
                isMulti={true}
                options={masters?.others ?? []}
                value={otherIds}
                onChange={onChangeOther}
              />
            </div>
          </div>
          {/* 施工 会社名 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_3}px` }}>
            <span className="form-label">{t("construction_company")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect isMulti={true} options={companyOptions} value={companyIds} onChange={handleChangeCompany} />
            </div>
          </div>
          {/* 施工 監督名 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_4}px` }}>
            <span className="form-label">{t("construction_pic")}</span>
            <div className="w-170 d-ib ta-l">
              <ChargeSelect
                masters={chargeOptions}
                userId={userIds}
                groupId={groupIds}
                isMulti={true}
                onChange={(e) => {
                  const users = e ? e.filter((d) => d.category === "user") : [];
                  const groups = e ? e.filter((d) => d.category === "group") : [];

                  onChangeUser(users.map((u) => u.value));
                  onChangeGroup(groups.map((g) => g.value));
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          {/* 製油立会依頼 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_1}px` }}>
            <span className="form-label">{t("presence_request")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                isMulti={false}
                isClearable={true}
                options={checkpoint1Options}
                value={checkpoint1Id}
                onChange={onChangeCheckpoint1}
              />
            </div>
          </div>
          {/* 新規・継続 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_2}px` }}>
            <span className="form-label">{t("new_keep")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                isMulti={false}
                isClearable={true}
                options={checkpoint2Options}
                value={checkpoint2Id}
                onChange={onChangeCheckpoint2}
              />
            </div>
          </div>
          {/* 直接火気 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_3}px` }}>
            <span className="form-label">{t("hot_work_direct")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                isMulti={false}
                isClearable={true}
                options={checkpoint3Options}
                value={checkpoint3Id}
                onChange={onChangeCheckpoint3}
              />
            </div>
          </div>
          {/* 間接火気 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_4}px` }}>
            <span className="form-label">{t("hot_work_indirect")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                isMulti={false}
                isClearable={true}
                options={checkpoint4Options}
                value={checkpoint4Id}
                onChange={onChangeCheckpoint4}
              />
            </div>
          </div>
        </div>
        <div className="form-row">
          {/* 容器内作業 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_1}px` }}>
            <span className="form-label">{t("container_work")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                isMulti={false}
                isClearable={true}
                options={checkpoint5Options}
                value={checkpoint5Id}
                onChange={onChangeCheckpoint5}
              />
            </div>
          </div>
          {/* 非定常作業 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_2}px` }}>
            <span className="form-label">{t("abnormal_work")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                isMulti={false}
                isClearable={true}
                options={checkpoint6Options}
                value={checkpoint6Id}
                onChange={onChangeCheckpoint6}
              />
            </div>
          </div>
          {/* 工事担当部署 担当者 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_3}px` }}>
            <span className="form-label">{t("schedule_pic")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect isMulti={true} options={field1Options} value={field1Ids} onChange={handleChangeField1} />
            </div>
          </div>
          {/* 申請 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_4}px` }}>
            <span className="form-label">{t("request")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                isMulti={false}
                isClearable={true}
                options={requestUserIdOptions}
                value={requestUserId}
                onChange={onChangeRequestUser}
              />
            </div>
          </div>
        </div>
        <div className="form-row mb-0">
          {/* 製油確認者 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_1}px` }}>
            <span className="form-label">{t("pic_2")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect
                isMulti={false}
                isClearable={true}
                options={field5Options}
                value={field5Ids}
                onChange={onChangeField5}
              />
            </div>
          </div>
          {/* 許可 承認 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_2}px` }}>
            <span className="form-label">{t("permission_approval")}</span>
            <div className="w-170 d-ib ta-l">
              <MasterSelect isMulti={true} options={approveOptions} value={approveIds} onChange={handleChangeApprove} />
            </div>
          </div>
          {/* 時間外申請 */}
          <div className="form-group" style={{ width: `${COLUMN_WIDTH_3}px` }}>
            <span className="form-label">&nbsp;</span>
            <div className="w-170 d-ib ta-l my-[5px]">
              <label className="ckbox">
                <input
                  type="checkbox"
                  value={1}
                  checked={filter === 1}
                  onChange={(e) => onChangeFilter(e.target.checked ? 1 : undefined)}
                />
                <span>{t("overtime_application")}</span>
              </label>
            </div>
          </div>
          {/* リセット / 検索 ボタン */}
          <div className="form-group btn-area pull-right mt-0">
            <button data-test-id="button-schedule-reset" className="btn btn-gray" onClick={onClear}>
              {t("reset")}
            </button>
            <button data-test-id="button-schedule-search" className="btn btn-blue" onClick={() => onSearch()}>
              {t("search")}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

ScheduleChibaSearchBox.displayName = "ScheduleChibaSearchBox";
