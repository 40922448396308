// Enum
export const TOAST_CONTENT_TYPES = {
  ERROR: "ERROR",
};

// Menulist static table
export const MENULIST = [
  { id: 1000, name: "home", alias: "home" },
  { id: 1001, name: "import", alias: "dataimport" },
  { id: 1002, name: "summary", alias: "summary" },
  { id: 1003, name: "matrix", alias: "matrix" },
  { id: 1004, name: "system", alias: "system" },
  { id: 1005, name: "approval", alias: "approval" },
  { id: 1006, name: "inspection", alias: "inspection" },
  { id: 1007, name: "qrcode", alias: "qrcode" },
  { id: 1008, name: "asset", alias: "asset" },
  { id: 1009, name: "schedule", alias: "schedule" },
  { id: 1010, name: "unworkdays", alias: "unworkdays" },
  { id: 1011, name: "category", alias: "category" },
  { id: 1012, name: "checkpoint", alias: "checkpoint" },
  { id: 1021, name: "area", alias: "area" },
  { id: 1022, name: "device", alias: "device" },
  { id: 1023, name: "facility_management", alias: "facility_management" },
  {
    id: 1024,
    name: "construction_management",
    alias: "construction_management",
  },
  { id: 1025, name: "primary_charge", alias: "primary_charge" },
  { id: 1026, name: "other", alias: "other" },
  { id: 1031, name: "process_major_class", alias: "process_major_class" },
  { id: 1032, name: "process_middle_class", alias: "process_middle_class" },
  {
    id: 1101,
    name: "schedule_eneos_kawasaki",
    alias: "schedule_eneos_kawasaki",
  },
  { id: 1102, name: "schedule_chita", alias: "schedule_chita" },
  { id: 1103, name: "schedule_chiba", alias: "schedule_chiba" },
  { id: 2001, name: "machine", alias: "machine" },
  { id: 2002, name: "machine_type", alias: "machine_type" },
  { id: 2003, name: "plot_plan", alias: "plot_plan" },
];

// Matrix download files static table
export const MATRIX_DOWNLOADS = [
  {
    label: "mspj_actual_data",
    filetype: 2,
    filename: "results_data.csv",
    format: "csv",
  },
  {
    label: "actual_schedule",
    filetype: 3,
    filename: "results_step.xls",
    format: "excel",
  },
  {
    label: "schedule_list",
    filetype: 4, // 4:作業基準、13:件名基準 予定表の場合はモーダルで指定するのでこの値は使わない
    filename: "schedule.xls",
    format: "excel",
  },
  //{label: '履歴',             filetype: 6,  filename: 'history.xls',              format: 'excel'},
  //{label: '一覧',             filetype: 7,  filename: 'list.xls',                 format: 'excel'},
  {
    label: "control_sheet",
    filetype: 9,
    filename: "management_list.xls",
    format: "excel",
  },
  {
    label: "control_sheet",
    filetype: 9,
    filename: "management_list.pdf",
    format: "pdf",
  },
  {
    label: "actual_progress_sch",
    filetype: 10,
    filename: "progress_plan.pdf",
    format: "pdf",
  },
  {
    label: "approval_setting",
    filetype: 11,
    filename: "approval_settings.pdf",
    format: "pdf",
  },
  {
    label: "progress_schedule_list",
    filetype: 12,
    filename: "progress_input.pdf",
    format: "pdf",
  },
];

// Accept upload file extensions
export const ACCEPT_UPLOAD_EXTENSIONS = [".xlsx", ".xls", ".xlsm", ".xlm", ".pdf", ".jpeg", ".jpg", ".png"];

// Language Options
export const LANG_OPTIONS = [
  { label: "日本語", value: "ja" },
  { label: "English", value: "en" },
];

// 進捗マトリクスの検索の選択肢の"設定なし"の値
export const NONE_VALUE = -1;

// セキュリティコード送信先国番号
export const COUNTRY_NUMS = [81];

/**
 * Action naming convention
 * [PREFIX]_[EVENT]_[TARGET]
 */

// Global Actions
export const APP_CONTENTS_READY = "APP_CONTENTS_READY";

export const APP_POPSTATE = "APP_POPSTATE";

export const APP_SET_UNLOAD_ALERT = "APP_SET_UNLOAD_ALERT";

export const APP_CLEAR_UNLOAD_ALERT = "APP_CLEAR_UNLOAD_ALERT";

export const APP_BEGIN_FETCH_ROLES = "APP_BEGIN_FETCH_ROLES";

export const APP_END_FETCH_ROLES = "APP_END_FETCH_ROLES";

export const APP_BEGIN_FETCH_MENUS = "APP_BEGIN_FETCH_MENUS";

export const APP_END_FETCH_MENUS = "APP_END_FETCH_MENUS";

export const APP_CHANGE_MENU = "APP_CHANGE_MENU";

export const APP_SHOW_CONSTRUCTION_SELECTOR = "APP_SHOW_CONSTRUCTION_SELECTOR";

export const APP_HIDE_CONSTRUCTION_SELECTOR = "APP_HIDE_CONSTRUCTION_SELECTOR";

export const APP_SHOW_PASSWORD_EDITOR = "APP_SHOW_PASSWORD_EDITOR";

export const APP_HIDE_PASSWORD_EDITOR = "APP_HIDE_PASSWORD_EDITOR";

export const APP_BEGIN_UPDATE_PASSWORD = "APP_BEGIN_UPDATE_PASSWORD";

export const APP_END_UPDATE_PASSWORD = "APP_END_UPDATE_PASSWORD";

export const APP_ADD_TOAST_CONTENT = "APP_ADD_TOAST_CONTENT";

export const APP_REMOVE_TOAST_CONTENT = "APP_REMOVE_TOAST_CONTENT";

export const APP_CALL_API = "APP_CALL_API";

export const APP_FILE_UPLOAD = "APP_FILE_UPLOAD";

export const APP_FAILED_UPLOAD = "APP_FAILED_UPLOAD";

export const APP_SHOW_ERROR = "APP_SHOW_ERROR";

export const APP_SHOW_ALERT = "APP_SHOW_ALERT";

export const APP_HIDE_ALERT = "APP_HIDE_ALERT";

export const APP_SHOW_CONFIRM = "APP_SHOW_CONFIRM";

export const APP_HIDE_CONFIRM = "APP_HIDE_CONFIRM";

export const APP_CHANGE_LANG = "APP_CHANGE_LANG";

export const APP_SAVE_LANG = "APP_SAVE_LANG";

export const APP_SHOW_LANG_SELECTOR = "APP_SHOW_LANG_SELECTOR";

export const APP_HIDE_LANG_SELECTOR = "APP_HIDE_LANG_SELECTOR";

export const APP_SWITCH_SYSTEM = "APP_SWITCH_SYSTEM";

// Common Actions
export const COMMON_BEGIN_CHANGE_TASK_STATUS = "COMMON_BEGIN_CHANGE_TASK_STATUS";

export const COMMON_END_CHANGE_TASK_STATUS = "COMMON_END_CHANGE_TASK_STATUS";

export const COMMON_ERROR_CHANGE_TASK_STATUS = "COMMON_ERROR_CHANGE_TASK_STATUS";

export const COMMON_BEGIN_DOWNLOAD_ATTACHMENT_FILE = "COMMON_BEGIN_DOWNLOAD_ATTACHMENT_FILE";

export const COMMON_END_DOWNLOAD_ATTACHMENT_FILE = "COMMON_END_DOWNLOAD_ATTACHMENT_FILE";

// Login Actions
export const LOGIN_RESET_STATE = "LOGIN_RESET_STATE";

export const LOGIN_CHANGE_NAME = "LOGIN_CHANGE_NAME";

export const LOGIN_CHANGE_PASSWORD = "LOGIN_CHANGE_PASSWORD";

export const LOGIN_VALIDATION_FAILED = "LOGIN_VALIDATION_FAILED";

export const LOGIN_BEGIN_AUTH = "LOGIN_BEGIN_AUTH";

export const LOGIN_END_AUTH = "LOGIN_END_AUTH";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGIN_SHOULD_LOGOUT = "LOGIN_SHOULD_LOGOUT";

export const LOGIN_SHOW_PASSWORD_WARNING = "LOGIN_SHOW_PASSWORD_WARNING";

export const LOGIN_HIDE_PASSWORD_WARNING = "LOGIN_HIDE_PASSWORD_WARNING";

export const LOGIN_SHOW_PASSWORD_EDITOR = "LOGIN_SHOW_PASSOWRD_EDITOR";

export const LOGIN_HIDE_PASSWORD_EDITOR = "LOGIN_HIDE_PASSWORD_EDITOR";

export const LOGIN_BEGIN_TWO_FACTOR_AUTH = "LOGIN_BEGIN_TWO_FACTOR_AUTH";

export const LOGIN_END_TWO_FACTOR_AUTH = "LOGIN_END_TWO_FACTOR_AUTH";

export const LOGIN_FAILED_TWO_FACTOR_AUTH = "LOGIN_FAILED_TWO_FACTOR_AUTH";

export const LOGIN_BEGIN_NEXT_AUTH = "LOGIN_BEGIN_NEXT_AUTH";

export const LOGIN_END_NEXT_AUTH = "LOGIN_END_NEXT_AUTH";

export const LOGIN_SHOW_TWO_FACTOR_AUTH = "LOGIN_SHOW_TWO_FACTOR_AUTH";

export const LOGIN_HIDE_TWO_FACTOR_AUTH = "LOGIN_HIDE_TWO_FACTOR_AUTH";

export const LOGIN_BEGIN_UPDATE_PASSWORD = "LOGIN_BEGIN_UPDATE_PASSWORD";

export const LOGIN_END_UPDATE_PASSWORD = "LOGIN_END_UPDATE_PASSWORD";

// Logout Actions
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// Construction Actions
export const CONSTRUCTION_SAVE = "CONSTRUCTION_SAVE";

export const CONSTRUCTION_BEGIN_FETCH_ITEMS = "CONSTRUCTION_BEGIN_FETCH_ITEMS";

export const CONSTRUCTION_END_FETCH_ITEMS = "CONSTRUCTION_END_FETCH_ITEMS";

export const CONSTRUCTION_BEGIN_FETCH_MASTERS = "CONSTRUCTION_BEGIN_FETCH_MASTERS";

export const CONSTRUCTION_END_FETCH_MASTERS = "CONSTRUCTION_END_FETCH_MASTERS";

export const CONSTRUCTION_BEGIN_FETCH_PROGRESS_RATE = "CONSTRUCTION_BEGIN_FETCH_PROGRESS_RATE";

export const CONSTRUCTION_END_FETCH_PROGRESS_RATE = "CONSTRUCTION_END_FETCH_PROGRESS_RATE";

export const CONSTRUCTION_BEGIN_FETCH_EXTENSIONS = "CONSTRUCTION_BEGIN_FETCH_EXTENSIONS";

export const CONSTRUCTION_END_FETCH_EXTENSIONS = "CONSTRUCTION_END_FETCH_EXTENSIONS";

export const CONSTRUCTION_BEGIN_FETCH_LINKAGE_TASKS = "CONSTRUCTION_BEGIN_FETCH_LINKAGE_TASKS";

export const CONSTRUCTION_END_FETCH_LINKAGE_TASKS = "CONSTRUCTION_END_FETCH_LINKAGE_TASKS";

// Information Actions
export const INFORMATION_BEGIN_FETCH_ITEMS = "INFORMATION_BEGIN_FETCH_ITEMS";

export const INFORMATION_END_FETCH_ITEMS = "INFORMATION_END_FETCH_ITEMS";

export const INFORMATION_BEGIN_FETCH_ITEM = "INFORMATION_BEGIN_FETCH_ITEM";

export const INFORMATION_END_FETCH_ITEM = "INFORMATION_END_FETCH_ITEM";

export const INFORMATION_BEGIN_DELETE_ITEM = "INFORMATION_BEGIN_DELETE_ITEM";

export const INFORMATION_END_DELETE_ITEM = "INFORMATION_END_DELETE_ITEM";

export const INFORMATION_BEGIN_CREATE_ITEM = "INFORMATION_BEGIN_CREATE_ITEM";

export const INFORMATION_END_CREATE_ITEM = "INFORMATION_END_CREATE_ITEM";

export const INFORMATION_BEGIN_UPDATE_ITEM = "INFORMATION_BEGIN_UPDATE_ITEM";

export const INFORMATION_END_UPDATE_ITEM = "INFORMATION_END_UPDATE_ITEM";

export const INFORMATION_SET_START = "INFORMATION_SET_START";

export const INFORMATION_SET_LIMIT = "INFORMATION_SET_LIMIT";

export const INFORMATION_SET_END = "INFORMATION_SET_END";

export const INFORMATION_RESET_STATE = "INFORMATION_RESET_STATE";

// Summary Actions
export const SUMMARY_CHANGE_AREA = "SUMMARY_CHANGE_AREA";

export const SUMMARY_CHANGE_DEVICE = "SUMMARY_CHANGE_DEVICE";

export const SUMMARY_CHANGE_CATEGORY = "SUMMARY_CHANGE_CATEGORY";

export const SUMMARY_CHANGE_FACILITY_MANAGEMENT = "SUMMARY_CHANGE_FACILITY_MANAGEMENT";

export const SUMMARY_CHANGE_CONSTRUCTION_MANAGEMENT = "SUMMARY_CHANGE_CONSTRUCTION_MANAGEMENT";

export const SUMMARY_CHANGE_PRIMARY_CHARGE = "SUMMARY_CHANGE_PRIMARY_CHARGE";

export const SUMMARY_RESET_SEARCH = "SUMMARY_RESET_SEARCH";

export const SUMMARY_BEGIN_SEARCH_ITEMS = "SUMMARY_BEGIN_SEARCH_ITEMS";

export const SUMMARY_END_SEARCH_ITEMS = "SUMMARY_END_SEARCH_ITEMS";

export const SUMMARY_BEGIN_SEARCH_ITEMS_INFINITE = "SUMMARY_BEGIN_SEARCH_ITEMS_INFINITE";

export const SUMMARY_END_SEARCH_ITEMS_INFINITE = "SUMMARY_END_SEARCH_ITEMS_INFINITE";

export const SUMMARY_END_DOWNLOAD = "SUMMARY_END_DOWNLOAD";

export const SUMMARY_SELECT_CATEGORY_SUMMARY = "SUMMARY_SELECT_CATEGORY_SUMMARY";

export const SUMMARY_SET_VALIDATED = "SUMMARY_SET_VALIDATED";

export const SUMMARY_REVERT_CONDITION = "IMPORT_REVERT_CONDITION";

export const SUMMARY_BEGIN_SELECT_INAZUMA = "SUMMARY_BEGIN_SELECT_INAZUMA";

export const SUMMARY_END_SELECT_INAZUMA = "SUMMARY_END_SELECT_INAZUMA";

export const SUMMARY_TOGGLE_INAZUMA_CELL = "SUMMARY_TOGGLE_INAZUMA_CELL";

export const SUMMARY_CLEAR_INAZUMA_CELLS = "SUMMARY_CLEAR_INAZUMA_CELLS";

export const SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST = "SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST";

export const SUMMARY_END_INAZUMA_PANEL_REQUEST = "SUMMARY_END_INAZUMA_PANEL_REQUEST";

export const SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST_CREATION = "SUMMARY_BEGIN_INAZUMA_PANEL_REQUEST_CREATION";

export const SUMMARY_END_INAZUMA_PANEL_REQUEST_CREATION = "SUMMARY_END_INAZUMA_PANEL_REQUEST_CREATION";

export const SUMMARY_BEGIN_INAZUMA_PANEL_CREATE_BASE = "SUMMARY_BEGIN_INAZUMA_PANEL_CREATE_BASE";

export const SUMMARY_END_INAZUMA_PANEL_CREATE_BASE = "SUMMARY_END_INAZUMA_PANEL_CREATE_BASE";

export const SUMMARY_BEGIN_SELECT_GRAPH = "SUMMARY_BEGIN_SELECT_GRAPH";

export const SUMMARY_END_SELECT_GRAPH = "SUMMARY_END_SELECT_GRAPH";

export const SUMMARY_BEGIN_SELECT_DELAY = "SUMMARY_BEGIN_SELECT_DELAY";

export const SUMMARY_END_SELECT_DELAY = "SUMMARY_END_SELECT_DELAY";

export const SUMMARY_TOGGLE_DELAY_CELL = "SUMMARY_TOGGLE_DELAY_CELL";

export const SUMMARY_CLEAR_DELAY_CELLS = "SUMMARY_CLEAR_DELAY_CELLS";

export const SUMMARY_TOGGLE_GRAPH_CELL = "SUMMARY_TOGGLE_GRAPH_CELL";

export const SUMMARY_CLEAR_GRAPH_CELLS = "SUMMARY_CLEAR_GRAPH_CELLS";

export const SUMMARY_BEGIN_CREATE_GRAPH = "SUMMARY_BEGIN_CREATE_GRAPH";

export const SUMMARY_END_CREATE_GRAPH = "SUMMARY_END_CREATE_GRAPH";

export const SUMMARY_BEGIN_CREATE_DELAY_LIST = "SUMMARY_BEGIN_CREATE_DELAY_LIST";

export const SUMMARY_END_CREATE_DELAY_LIST = "SUMMARY_END_CREATE_DELAY_LIST";

export const SUMMARY_BEGIN_SELECT_SCHEDULE = "SUMMARY_BEGIN_SELECT_SCHEDULE";

export const SUMMARY_END_SELECT_SCHEDULE = "SUMMARY_END_SELECT_SCHEDULE";

export const SUMMARY_TOGGLE_SCHEDULE_CELL = "SUMMARY_TOGGLE_SCHEDULE_CELL";

export const SUMMARY_CLEAR_SCHEDULE_CELLS = "SUMMARY_CLEAR_SCHEDULE_CELLS";

export const SUMMARY_BEGIN_SCHEDULE_PANEL_REQUEST_CREATION = "SUMMARY_BEGIN_SCHEDULE_PANEL_REQUEST_CREATION";

export const SUMMARY_END_SCHEDULE_PANEL_REQUEST_CREATION = "SUMMARY_END_SCHEDULE_PANEL_REQUEST_CREATION";

export const SUMMARY_BEGIN_SCHEDULE_PANEL_CREATE_BASE = "SUMMARY_BEGIN_SCHEDULE_PANEL_CREATE_BASE";

export const SUMMARY_BEGIN_CREATE_SCHEDULE = "SUMMARY_BEGIN_CREATE_SCHEDULE";

export const SUMMARY_END_CREATE_SCHEDULE = "SUMMARY_END_CREATE_SCHEDULE";

// Approval Actions
export const APPROVAL_CHANGE_AREA = "APPROVAL_CHANGE_AREA";

export const APPROVAL_CHANGE_DEVICE = "APPROVAL_CHANGE_DEVICE";

export const APPROVAL_CHANGE_CATEGORY = "APPROVAL_CHANGE_CATEGORY";

export const APPROVAL_CHANGE_FACILITY_MANAGEMENT = "APPROVAL_CHANGE_FACILITY_MANAGEMENT";

export const APPROVAL_CHANGE_CONSTRUCTION_MANAGEMENT = "APPROVAL_CHANGE_CONSTRUCTION_MANAGEMENT";

export const APPROVAL_CHANGE_PRIMARY_CHARGE = "APPROVAL_CHANGE_PRIMARY_CHARGE";

export const APPROVAL_CHANGE_OTHER = "APPROVAL_CHANGE_OTHER";

export const APPROVAL_CHANGE_PROCESS_MAJOR_CLASS = "APPROVAL_CHANGE_PROCESS_MAJOR_CLASS";

export const APPROVAL_CHANGE_PROCESS_MIDDLE_CLASS = "APPROVAL_CHANGE_PROCESS_MIDDLE_CLASS";

export const APPROVAL_CHANGE_COMPANY = "APPROVAL_CHANGE_COMPANY";

export const APPROVAL_CHANGE_SCHEDULE_DATE = "APPROVAL_CHANGE_SCHEDULE_DATE";

export const APPROVAL_CHANGE_CHECKPOINT = "APPROVAL_CHANGE_CHECKPOINT";

export const APPROVAL_CHANGE_ITEM_TEXT = "APPROVAL_CHANGE_ITEM_TEXT";

export const APPROVAL_CHANGE_PROCESS_TEXT = "APPROVAL_CHANGE_PROCESS_TEXT";

export const APPROVAL_CHANGE_FILTER = "APPROVAL_CHANGE_FILTER";

export const APPROVAL_BEGIN_SEARCH = "APPROVAL_BEGIN_SEARCH";

export const APPROVAL_END_SEARCH = "APPROVAL_END_SEARCH";

export const APPROVAL_CLEAR_SEARCH = "APPROVAL_CLEAR_SEARCH";

export const APPROVAL_CHANGE_SWITCHER_STATUS = "APPROVAL_CHANGE_SWITCHER_STATUS";

export const APPROVAL_SET_VALIDATED = "APPROVAL_SET_VALIDATED";

export const APPROVAL_REVERT_CONDITION = "APPROVAL_REVERT_CONDITION";

// Inspection Actions
export const INSPECTION_CHANGE_AREA = "INSPECTION_CHANGE_AREA";

export const INSPECTION_CHANGE_DEVICE = "INSPECTION_CHANGE_DEVICE";

export const INSPECTION_CHANGE_CATEGORY = "INSPECTION_CHANGE_CATEGORY";

export const INSPECTION_CHANGE_FACILITY_MANAGEMENT = "INSPECTION_CHANGE_FACILITY_MANAGEMENT";

export const INSPECTION_CHANGE_CONSTRUCTION_MANAGEMENT = "INSPECTION_CHANGE_CONSTRUCTION_MANAGEMENT";

export const INSPECTION_CHANGE_PRIMARY_CHARGE = "INSPECTION_CHANGE_PRIMARY_CHARGE";

export const INSPECTION_CHANGE_OTHER = "INSPECTION_CHANGE_OTHER";

export const INSPECTION_CHANGE_PROCESS_MAJOR_CLASS = "INSPECTION_CHANGE_PROCESS_MAJOR_CLASS";

export const INSPECTION_CHANGE_SCHEDULE_DATE = "INSPECTION_CHANGE_SCHEDULE_DATE";

export const INSPECTION_CHANGE_CHECKPOINT = "INSPECTION_CHANGE_CHECKPOINT";

export const INSPECTION_CHANGE_ITEM_TEXT = "INSPECTION_CHANGE_ITEM_TEXT";

export const INSPECTION_CHANGE_PROCESS_TEXT = "INSPECTION_CHANGE_PROCESS_TEXT";

export const INSPECTION_CHANGE_FILTER = "INSPECTION_CHANGE_FILTER";

export const INSPECTION_BEGIN_SEARCH = "INSPECTION_BEGIN_SEARCH";

export const INSPECTION_END_SEARCH = "INSPECTION_END_SEARCH";

export const INSPECTION_BEGIN_SEARCH_INFINITE = "INSPECTION_BEGIN_SEARCH_INFINITE";

export const INSPECTION_END_SEARCH_INFINITE = "INSPECTION_END_SEARCH_INFINITE";

export const INSPECTION_CLEAR_SEARCH = "INSPECTION_CLEAR_SEARCH";

export const INSPECTION_CHANGE_SWITCHER_STATUS = "INSPECTION_CHANGE_SWITCHER_STATUS";

export const INSPECTION_SET_VALIDATED = "INSPECTION_SET_VALIDATED";

export const INSPECTION_REVERT_CONDITION = "INSPECTION_REVERT_CONDITION";

// Schedule Actions (Latest)
export const SCHEDULES_BEGIN_GET_OPTIONS = "SCHEDULES_BEGIN_GET_OPTIONS";

export const SCHEDULES_END_GET_OPTIONS = "SCHEDULES_END_GET_OPTIONS";

export const SCHEDULES_BEGIN_GET_RESULTS = "SCHEDULES_BEGIN_GET_RESULTS";

export const SCHEDULES_END_GET_RESULTS = "SCHEDULES_END_GET_RESULTS";

export const SCHEDULES_BEGIN_GET_SCHEDULES = "SCHEDULES_BEGIN_GET_SCHEDULES";

export const SCHEDULES_END_GET_SCHEDULES = "SCHEDULES_END_GET_SCHEDULES";

// 予定表1件更新
export const SCHEDULES_BEGIN_UPDATE_SCHEDULE = "SCHEDULES_BEGIN_UPDATE_SCHEDULE";

export const SCHEDULES_END_UPDATE_SCHEDULE = "SCHEDULES_END_UPDATE_SCHEDULE";

export const SCHEDULES_BEGIN_UPDATE_SCHEDULE_WITH_TASK = "SCHEDULES_BEGIN_UPDATE_SCHEDULE_WITH_TASK";

export const SCHEDULES_END_UPDATE_SCHEDULE_WITH_TASK = "SCHEDULES_END_UPDATE_SCHEDULE_WITH_TASK";

export const SCHEDULES_END_UPDATE_OVERTIME = "SCHEDULES_END_UPDATE_OVERTIME";

export const SCHEDULES_BEGIN_UPDATE_SCHEDULE_WITH_PROCESS = "SCHEDULES_BEGIN_UPDATE_SCHEDULE_WITH_PROCESS";

export const SCHEDULES_END_UPDATE_SCHEDULE_WITH_PROCESS = "SCHEDULES_END_UPDATE_SCHEDULE_WITH_PROCESS";

export const SCHEDULES_BEGIN_APPROVE_SCHEDULE = "SCHEDULES_BEGIN_APPROVE_SCHEDULE";

export const SCHEDULES_END_APPROVE_SCHEDULE = "SCHEDULES_END_APPROVE_SCHEDULE";

// 予定表一括更新
export const SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE = "SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE";

export const SCHEDULES_END_BULK_UPDATE_SCHEDULE = "SCHEDULES_END_BULK_UPDATE_SCHEDULE";

export const SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE_WITH_TASK = "SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE_WITH_TASK";

export const SCHEDULES_END_BULK_UPDATE_SCHEDULE_WITH_TASK = "SCHEDULES_END_BULK_UPDATE_SCHEDULE_WITH_TASK";

export const SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE_WITH_PROCESS = "SCHEDULES_BEGIN_BULK_UPDATE_SCHEDULE_WITH_PROCESS";

export const SCHEDULES_END_BULK_UPDATE_SCHEDULE_WITH_PROCESS = "SCHEDULES_END_BULK_UPDATE_SCHEDULE_WITH_PROCESS";

export const SCHEDULES_BEGIN_BULK_APPROVE_SCHEDULE = "SCHEDULES_BEGIN_BULK_APPROVE_SCHEDULE";

export const SCHEDULES_END_BULK_APPROVE_SCHEDULE = "SCHEDULES_END_BULK_APPROVE_SCHEDULE";

// 予定表ダウンロード
export const SCHEDULES_BEGIN_GET_FILES = "SCHEDULES_BEGIN_GET_FILES";

export const SCHEDULES_END_GET_FILES = "SCHEDULES_END_GET_FILES";

// Schedule Actions
export const SCHEDULE_CHANGE_AREA = "SCHEDULE_CHANGE_AREA";

export const SCHEDULE_CHANGE_DEVICE = "SCHEDULE_CHANGE_DEVICE";

export const SCHEDULE_CHANGE_CATEGORY = "SCHEDULE_CHANGE_CATEGORY";

export const SCHEDULE_CHANGE_FACILITY_MANAGEMENT = "SCHEDULE_CHANGE_FACILITY_MANAGEMENT";

export const SCHEDULE_CHANGE_CONSTRUCTION_MANAGEMENT = "SCHEDULE_CHANGE_CONSTRUCTION_MANAGEMENT";

export const SCHEDULE_CHANGE_PRIMARY_CHARGE = "SCHEDULE_CHANGE_PRIMARY_CHARGE";

export const SCHEDULE_CHANGE_OTHER = "SCHEDULE_CHANGE_OTHER";

export const SCHEDULE_CHANGE_USER = "SCHEDULE_CHANGE_USER";

export const SCHEDULE_CHANGE_GROUP = "SCHEDULE_CHANGE_GROUP";

export const SCHEDULE_CHANGE_USER_FIELD1 = "SCHEDULE_CHANGE_USER_FIELD1";

export const SCHEDULE_CHANGE_USER_FIELD7 = "SCHEDULE_CHANGE_USER_FIELD7";

export const SCHEDULE_CHANGE_USER_FIELD8 = "SCHEDULE_CHANGE_USER_FIELD8";

export const SCHEDULE_CHANGE_SCHEDULE_DATE = "SCHEDULE_CHANGE_SCHEDULE_DATE";

export const SCHEDULE_CHANGE_ITEM_TEXT = "SCHEDULE_CHANGE_ITEM_TEXT";

export const SCHEDULE_CHANGE_PROCESS_TEXT = "SCHEDULE_CHANGE_PROCESS_TEXT";

export const SCHEDULE_BEGIN_SEARCH = "SCHEDULE_BEGIN_SEARCH";

export const SCHEDULE_END_SEARCH = "SCHEDULE_END_SEARCH";

export const SCHEDULE_BEGIN_SEARCH_INFINITE = "SCHEDULE_BEGIN_SEARCH_INFINITE";

export const SCHEDULE_END_SEARCH_INFINITE = "SCHEDULE_END_SEARCH_INFINITE";

export const SCHEDULE_CLEAR_SEARCH = "SCHEDULE_CLEAR_SEARCH";

export const SCHEDULE_SET_VALIDATED = "SCHEDULE_SET_VALIDATED";

export const SCHEDULE_REVERT_CONDITION = "SCHEDULE_REVERT_CONDITION";

export const SCHEDULE_END_DOWNLOAD = "SCHEDULE_END_DOWNLOAD";

export const SCHEDULE_END_UPDATE_ITEM = "SCHEDULE_END_UPDATE_ITEM";

export const SCHEDULE_END_UPDATE_ITEM_BUNDLE = "SCHEDULE_END_UPDATE_ITEM_BUNDLE";

export const SCHEDULE_BEGIN_GET_OPTIONS = "SCHEDULE_BEGIN_GET_OPTIONS";

export const SCHEDULE_END_GET_OPTIONS = "SCHEDULE_END_GET_OPTIONS";

export const SCHEDULE_END_CREATE_SCHEDULE = "SCHEDULE_END_CREATE_SCHEDULE";

export const SCHEDULE_SET_VALID_REQUIRED = "SCHEDULE_SET_VALID_REQUIRED";

// Schedule Chita Actions
export const SCHEDULE_CHITA_CHANGE_AREA = "SCHEDULE_CHITA_CHANGE_AREA";

export const SCHEDULE_CHITA_CHANGE_DEVICE = "SCHEDULE_CHITA_CHANGE_DEVICE";

export const SCHEDULE_CHITA_CHANGE_CATEGORY = "SCHEDULE_CHITA_CHANGE_CATEGORY";

export const SCHEDULE_CHITA_CHANGE_FACILITY_MANAGEMENT = "SCHEDULE_CHITA_CHANGE_FACILITY_MANAGEMENT";

export const SCHEDULE_CHITA_CHANGE_CONSTRUCTION_MANAGEMENT = "SCHEDULE_CHITA_CHANGE_CONSTRUCTION_MANAGEMENT";

export const SCHEDULE_CHITA_CHANGE_PRIMARY_CHARGE = "SCHEDULE_CHITA_CHANGE_PRIMARY_CHARGE";

export const SCHEDULE_CHITA_CHANGE_OTHER = "SCHEDULE_CHITA_CHANGE_OTHER";

export const SCHEDULE_CHITA_CHANGE_USER = "SCHEDULE_CHITA_CHANGE_USER";

export const SCHEDULE_CHITA_CHANGE_USER_FIELD1 = "SCHEDULE_CHITA_CHANGE_USER_FIELD1";

export const SCHEDULE_CHITA_CHANGE_USER_FIELD2 = "SCHEDULE_CHITA_CHANGE_USER_FIELD2";

export const SCHEDULE_CHITA_CHANGE_USER_FIELD3 = "SCHEDULE_CHITA_CHANGE_USER_FIELD3";

export const SCHEDULE_CHITA_CHANGE_USER_FIELD4 = "SCHEDULE_CHITA_CHANGE_USER_FIELD4";

export const SCHEDULE_CHITA_CHANGE_SCHEDULE_DATE = "SCHEDULE_CHITA_CHANGE_SCHEDULE_DATE";

export const SCHEDULE_CHITA_CHANGE_ITEM_TEXT = "SCHEDULE_CHITA_CHANGE_ITEM_TEXT";

export const SCHEDULE_CHITA_CHANGE_PROCESS_TEXT = "SCHEDULE_CHITA_CHANGE_PROCESS_TEXT";

export const SCHEDULE_CHITA_BEGIN_SEARCH = "SCHEDULE_CHITA_BEGIN_SEARCH";

export const SCHEDULE_CHITA_END_SEARCH = "SCHEDULE_CHITA_END_SEARCH";

export const SCHEDULE_CHITA_BEGIN_SEARCH_INFINITE = "SCHEDULE_CHITA_BEGIN_SEARCH_INFINITE";

export const SCHEDULE_CHITA_END_SEARCH_INFINITE = "SCHEDULE_CHITA_END_SEARCH_INFINITE";

export const SCHEDULE_CHITA_CLEAR_SEARCH = "SCHEDULE_CHITA_CLEAR_SEARCH";

export const SCHEDULE_CHITA_SET_VALIDATED = "SCHEDULE_CHITA_SET_VALIDATED";

export const SCHEDULE_CHITA_REVERT_CONDITION = "SCHEDULE_CHITA_REVERT_CONDITION";

export const SCHEDULE_CHITA_END_DOWNLOAD = "SCHEDULE_CHITA_END_DOWNLOAD";

export const SCHEDULE_CHITA_END_UPDATE_ITEM = "SCHEDULE_CHITA_END_UPDATE_ITEM";

export const SCHEDULE_CHITA_END_UPDATE_ITEM_BUNDLE = "SCHEDULE_CHITA_END_UPDATE_ITEM_BUNDLE";

export const SCHEDULE_CHITA_BEGIN_GET_OPTIONS = "SCHEDULE_CHITA_BEGIN_GET_OPTIONS";

export const SCHEDULE_CHITA_END_GET_OPTIONS = "SCHEDULE_CHITA_END_GET_OPTIONS";

export const SCHEDULE_CHITA_END_CREATE_SCHEDULE = "SCHEDULE_CHITA_END_CREATE_SCHEDULE";

export const SCHEDULE_CHITA_SET_VALID_REQUIRED = "SCHEDULE_CHITA_SET_VALID_REQUIRED";

// Schedule Chiba Actions
export const SCHEDULE_CHIBA_CHANGE_AREA = "SCHEDULE_CHIBA_CHANGE_AREA";

export const SCHEDULE_CHIBA_CHANGE_DEVICE = "SCHEDULE_CHIBA_CHANGE_DEVICE";

export const SCHEDULE_CHIBA_CHANGE_CATEGORY = "SCHEDULE_CHIBA_CHANGE_CATEGORY";

export const SCHEDULE_CHIBA_CHANGE_FACILITY_MANAGEMENT = "SCHEDULE_CHIBA_CHANGE_FACILITY_MANAGEMENT";

export const SCHEDULE_CHIBA_CHANGE_CONSTRUCTION_MANAGEMENT = "SCHEDULE_CHIBA_CHANGE_CONSTRUCTION_MANAGEMENT";

export const SCHEDULE_CHIBA_CHANGE_PRIMARY_CHARGE = "SCHEDULE_CHIBA_CHANGE_PRIMARY_CHARGE";

export const SCHEDULE_CHIBA_CHANGE_OTHER = "SCHEDULE_CHIBA_CHANGE_OTHER";

export const SCHEDULE_CHIBA_CHANGE_CONSTRUCTION_COMPANY = "SCHEDULE_CHIBA_CHANGE_CONSTRUCTION_COMPANY";

export const SCHEDULE_CHIBA_CHANGE_FIELD1 = "SCHEDULE_CHIBA_CHANGE_FIELD1";

export const SCHEDULE_CHIBA_CHANGE_USER = "SCHEDULE_CHIBA_CHANGE_USER";

export const SCHEDULE_CHIBA_CHANGE_GROUP = "SCHEDULE_CHIBA_CHANGE_GROUP";

export const SCHEDULE_CHIBA_CHANGE_APPROVE_USER = "SCHEDULE_CHIBA_CHANGE_APPROVE_USER";

export const SCHEDULE_CHIBA_CHANGE_CHECKPOINT1 = "SCHEDULE_CHIBA_CHANGE_CHECKPOINT1";
export const SCHEDULE_CHIBA_CHANGE_CHECKPOINT2 = "SCHEDULE_CHIBA_CHANGE_CHECKPOINT2";
export const SCHEDULE_CHIBA_CHANGE_CHECKPOINT3 = "SCHEDULE_CHIBA_CHANGE_CHECKPOINT3";
export const SCHEDULE_CHIBA_CHANGE_CHECKPOINT4 = "SCHEDULE_CHIBA_CHANGE_CHECKPOINT4";
export const SCHEDULE_CHIBA_CHANGE_CHECKPOINT5 = "SCHEDULE_CHIBA_CHANGE_CHECKPOINT5";
export const SCHEDULE_CHIBA_CHANGE_CHECKPOINT6 = "SCHEDULE_CHIBA_CHANGE_CHECKPOINT6";
export const SCHEDULE_CHIBA_CHANGE_FILTER = "SCHEDULE_CHIBA_CHANGE_FILTER";
export const SCHEDULE_CHIBA_CHANGE_REQUEST_USER = "SCHEDULE_CHIBA_CHANGE_REQUEST_USER";
export const SCHEDULE_CHIBA_CHANGE_FIELD5 = "SCHEDULE_CHIBA_CHANGE_FIELD5";

export const SCHEDULE_CHIBA_CHANGE_SCHEDULE_DATE = "SCHEDULE_CHIBA_CHANGE_SCHEDULE_DATE";

export const SCHEDULE_CHIBA_BEGIN_SEARCH = "SCHEDULE_CHIBA_BEGIN_SEARCH";

export const SCHEDULE_CHIBA_END_SEARCH = "SCHEDULE_CHIBA_END_SEARCH";

export const SCHEDULE_CHIBA_BEGIN_SEARCH_INFINITE = "SCHEDULE_CHIBA_BEGIN_SEARCH_INFINITE";

export const SCHEDULE_CHIBA_END_SEARCH_INFINITE = "SCHEDULE_CHIBA_END_SEARCH_INFINITE";

export const SCHEDULE_CHIBA_CLEAR_SEARCH = "SCHEDULE_CHIBA_CLEAR_SEARCH";

export const SCHEDULE_CHIBA_SET_VALIDATED = "SCHEDULE_CHIBA_SET_VALIDATED";

export const SCHEDULE_CHIBA_REVERT_CONDITION = "SCHEDULE_CHIBA_REVERT_CONDITION";

export const SCHEDULE_CHIBA_END_DOWNLOAD = "SCHEDULE_CHIBA_END_DOWNLOAD";

export const SCHEDULE_CHIBA_END_UPDATE_ITEM = "SCHEDULE_CHIBA_END_UPDATE_ITEM";

export const SCHEDULE_CHIBA_END_UPDATE_ITEM_BUNDLE = "SCHEDULE_CHIBA_END_UPDATE_ITEM_BUNDLE";

export const SCHEDULE_CHIBA_BEGIN_GET_OPTIONS = "SCHEDULE_CHIBA_BEGIN_GET_OPTIONS";

export const SCHEDULE_CHIBA_END_GET_OPTIONS = "SCHEDULE_CHIBA_END_GET_OPTIONS";

export const SCHEDULE_CHIBA_END_CREATE_SCHEDULE = "SCHEDULE_CHIBA_END_CREATE_SCHEDULE";

export const SCHEDULE_CHIBA_SET_VALID_REQUIRED = "SCHEDULE_CHIBA_SET_VALID_REQUIRED";

export const SCHEDULE_CHIBA_PERMIT_TABLE_DOWNLOAD = "SCHEDULE_CHIBA_PERMIT_TABLE_DOWNLOAD";

export const SCHEDULE_CHIBA_END_GET_SCHEDULE_URL = "SCHEDULE_CHIBA_END_GET_SCHEDULE_URL";

export const SCHEDULE_CHIBA_END_CREATE_SCHEDULE_URL = "SCHEDULE_CHIBA_END_CREATE_SCHEDULE_URL";

export const SCHEDULE_CHIBA_END_UPDATE_SCHEDULE_URL = "SCHEDULE_CHIBA_END_UPDATE_SCHEDULE_URL";

export const SCHEDULE_CHIBA_END_DELETE_SCHEDULE_URL = "SCHEDULE_CHIBA_END_DELETE_SCHEDULE_URL";

export const SCHEDULE_CHIBA_END_EDIT_SCHEDULE_URL = "SCHEDULE_CHIBA_END_EDIT_SCHEDULE_URL";

export const SCHEDULE_CHIBA_END_GET_HISTORIES = "SCHEDULE_CHIBA_END_GET_HISTORIES";

// Matrix Actions
export const MATRIX_BEGIN_FETCH_DATA = "MATRIX_BEGIN_FETCH_DATA";

export const MATRIX_END_FETCH_DATA = "MATRIX_END_FETCH_DATA";

export const MATRIX_SAVE_APPROVALS = "MATRIX_SAVE_APPROVALS";

export const MATRIX_CHANGE_SWITCHER_STATUS = "MATRIX_CHANGE_SWITCHER_STATUS";

export const MATRIX_BEGIN_BULK_UPDATE_PROCESS = "MATRIX_BEGIN_BULK_UPDATE_PROCESS";

export const MATRIX_END_BULK_UPDATE_PROCESS = "MATRIX_END_BULK_UPDATE_PROCESS";

export const MATRIX_BEGIN_UPDATE_ITEM = "MATRIX_BEGIN_UPDATE_ITEM";

export const MATRIX_END_UPDATE_ITEM = "MATRIX_END_UPDATE_ITEM";

export const MATRIX_BEGIN_SORT_ITEM = "MATRIX_BEGIN_SORT_ITEM";

export const MATRIX_END_SORT_ITEM = "MATRIX_END_SORT_ITEM";

export const MATRIX_ADD_ITEM_PREV = "MATRIX_ADD_ITEM_PREV";

export const MATRIX_ADD_ITEM_NEXT = "MATRIX_ADD_ITEM_NEXT";

export const MATRIX_REMOVE_EMPTY_ITEM = "MATRIX_REMOVE_EMPTY_ITEM";

export const MATRIX_BEGIN_COPY_ITEM = "MATRIX_BEGIN_COPY_ITEM";

export const MATRIX_END_COPY_ITEM = "MATRIX_END_COPY_ITEM";

export const MATRIX_BEGIN_DELETE_ITEM = "MATRIX_BEGIN_DELETE_ITEM";

export const MATRIX_END_DELETE_ITEM = "MATRIX_END_DELETE_ITEM";

export const MATRIX_BEGIN_BULK_UPDATE_ITEM_STATUS = "MATRIX_BEGIN_BULK_UPDATE_ITEM_STATUS";

export const MATRIX_END_BULK_UPDATE_ITEM_STATUS = "MATRIX_END_BULK_UPDATE_ITEM_STATUS";

export const MATRIX_BEGIN_UPDATE_PROCESSES = "MATRIX_BEGIN_UPDATE_PROCESSES";

export const MATRIX_END_UPDATE_PROCESSES = "MATRIX_END_UPDATE_PROCESSES";

export const MATRIX_BEGIN_FETCH_PROCESS = "MATRIX_BEGIN_FETCH_PROCESS";

export const MATRIX_END_FETCH_PROCESS = "MATRIX_END_FETCH_PROCESS";

export const MATRIX_BEGIN_CREATE_PROCESS = "MATRIX_BEGIN_CREATE_PROCESS";

export const MATRIX_END_CREATE_PROCESS = "MATRIX_END_CREATE_PROCESS";

export const MATRIX_BEGIN_UPDATE_PROCESS = "MATRIX_BEGIN_UPDATE_PROCESS";

export const MATRIX_BEGIN_DELETE_PROCESS = "MATRIX_BEGIN_DELETE_PROCESS";

export const MATRIX_END_DELETE_PROCESS = "MATRIX_END_DELETE_PROCESS";

export const MATRIX_BEGIN_SORT_PROCESS = "MATRIX_BEGIN_SORT_PROCESS";

export const MATRIX_END_SORT_PROCESS = "MATRIX_END_SORT_PROCESS";

export const MATRIX_END_UPDATE_PROCESS = "MATRIX_END_UPDATE_PROCESS";

export const MATRIX_BEGIN_UPDATE_PROCESS_RECEIVER = "MATRIX_BEGIN_UPDATE_PROCESS_RECEIVER";

export const MATRIX_END_UPDATE_PROCESS_RECEIVER = "MATRIX_END_UPDATE_PROCESS_RECEIVER";

export const MATRIX_END_FETCH_PROCESS_SEGMENT = "MATRIX_END_FETCH_PROCESS_SEGMENT";

export const MATRIX_END_UPDATE_PROCESS_SEGMENT = "MATRIX_END_UPDATE_PROCESS_SEGMENT";

export const MATRIX_END_FETCH_PROCESS_SEGMENT_LABELS = "MATRIX_END_FETCH_PROCESS_SEGMENT_LABELS";

export const MATRIX_END_UPDATE_PROCESS_SEGMENT_LABELS = "MATRIX_END_UPDATE_PROCESS_SEGMENT_LABELS";

export const MATRIX_BEGIN_FETCH_SUBJECTS = "MATRIX_BEGIN_FETCH_SUBJECTS";

export const MATRIX_END_FETCH_SUBJECTS = "MATRIX_END_FETCH_SUBJECTS";

export const MATRIX_BEGIN_FETCH_ITEM = "MATRIX_BEGIN_FETCH_ITEM";

export const MATRIX_END_FETCH_ITEM = "MATRIX_END_FETCH_ITEM";

export const MATRIX_BEGIN_FETCH_TASK_DETAIL = "MATRIX_BEGIN_FETCH_TASK_DETAIL";

export const MATRIX_END_FETCH_TASK_DETAIL = "MATRIX_END_FETCH_TASK_DETAIL";

export const MATRIX_BEGIN_UPDATE_TASK_DETAIL = "MATRIX_BEGIN_UPDATE_TASK_DETAIL";

export const MATRIX_END_UPDATE_TASK_DETAIL = "MATRIX_END_UPDATE_TASK_DETAIL";

export const MATRIX_BEGIN_DOWNLOAD = "MATRIX_BEGIN_DOWNLOAD";

export const MATRIX_END_DOWNLOAD = "MATRIX_END_DOWNLOAD";

export const MATRIX_TOGGLE_DOWNLOADING = "MATRIX_TOGGLE_DOWNLOADING";

export const MATRIX_END_FETCH_TASK_NUM = "MATRIX_END_FETCH_TASK_NUM";

export const MATRIX_BEGIN_BULK_UPDATE_TASK_STATUS = "MATRIX_BEGIN_BULK_UPDATE_TASK_STATUS";

export const MATRIX_END_BULK_UPDATE_TASK_STATUS = "MATRIX_END_BULK_UPDATE_TASK_STATUS";

export const MATRIX_BEGIN_BULK_UPDATE_SPECIAL_PROCESS = "MATRIX_BEGIN_BULK_UPDATE_SPECIAL_PROCESS";

export const MATRIX_END_BULK_UPDATE_SPECIAL_PROCESS = "MATRIX_END_BULK_UPDATE_SPECIAL_PROCESS";

export const MATRIX_BEGIN_BULK_UPDATE_PROTECTION = "MATRIX_BEGIN_BULK_UPDATE_PROTECTION";

export const MATRIX_END_BULK_UPDATE_PROTECTION = "MATRIX_END_BULK_UPDATE_PROTECTION";

export const MATRIX_COLUMN_DRAG_START = "MATRIX_COLUMN_DRAG_START";
export const MATRIX_COLUMN_DRAG_OVER = "MATRIX_COLUMN_DRAG_OVER";
export const MATRIX_COLUMN_DRAG_SCROLL = "MATRIX_COLUMN_DRAG_SCROLL";
export const MATRIX_COLUMN_DRAG_END = "MATRIX_COLUMN_DRAG_END";

export const MATRIX_SET_TITLE_SUBMENU = "MATRIX_SET_TITLE_SUBMENU";
export const MATRIX_CLEAR_TITLE_SUBMENU = "MATRIX_CLEAR_TITLE_SUBMENU";
export const MATRIX_SET_COLUMN_SUBMENU = "MATRIX_SET_COLUMN_SUBMENU";
export const MATRIX_CLEAR_COLUMN_SUBMENU = "MATRIX_CLEAR_COLUMN_SUBMENU";

// Matrix Search Actions
export const MATRIXSEARCH_CHANGE_AREA = "MATRIXSEARCH_CHANGE_AREA";

export const MATRIXSEARCH_CHANGE_DEVICE = "MATRIXSEARCH_CHANGE_DEVICE";

export const MATRIXSEARCH_CHANGE_CATEGORY = "MATRIXSEARCH_CHANGE_CATEGORY";

export const MATRIXSEARCH_CHANGE_FACILITY_MANAGEMENT = "MATRIXSEARCH_CHANGE_FACILITY_MANAGEMENT";

export const MATRIXSEARCH_CHANGE_CONSTRUCTION_MANAGEMENT = "MATRIXSEARCH_CHANGE_CONSTRUCTION_MANAGEMENT";

export const MATRIXSEARCH_CHANGE_PRIMARY_CHARGE = "MATRIXSEARCH_CHANGE_PRIMARY_CHARGE";

export const MATRIXSEARCH_CHANGE_OTHER = "MATRIXSEARCH_CHANGE_OTHER";

export const MATRIXSEARCH_CHANGE_SYSTEM = "MATRIXSEARCH_CHANGE_SYSTEM";

export const MATRIXSEARCH_CHANGE_COMPANY = "MATRIXSEARCH_CHANGE_COMPANY";

export const MATRIXSEARCH_CHANGE_USER = "MATRIXSEARCH_CHANGE_USER";

export const MATRIXSEARCH_CHANGE_GROUP = "MATRIXSEARCH_CHANGE_GROUP";

export const MATRIXSEARCH_CHANGE_MIDDLE = "MATRIXSEARCH_CHANGE_MIDDLE";

export const MATRIXSEARCH_CHANGE_ITEM_TEXT = "MATRIXSEARCH_CHANGE_ITEM_TEXT";

export const MATRIXSEARCH_CHANGE_PROCESS_TEXT = "MATRIXSEARCH_CHANGE_PROCESS_TEXT";

export const MATRIXSEARCH_CHANGE_REGULATION = "MATRIXSEARCH_CHANGE_REGULATION";

export const MATRIXSEARCH_CHANGE_FILTER = "MATRIXSEARCH_CHANGE_FILTER";

export const MATRIXSEARCH_BEGIN_SEARCH = "MATRIXSEARCH_BEGIN_SEARCH";

export const MATRIXSEARCH_END_SEARCH = "MATRIXSEARCH_END_SEARCH";

export const MATRIXSEARCH_BEGIN_SEARCH_PROCESSES = "MATRIXSEARCH_BEGIN_SEARCH_PROCESSES";

export const MATRIXSEARCH_END_SEARCH_PROCESSES = "MATRIXSEARCH_END_SEARCH_PROCESSES";

export const MATRIXSEARCH_BEGIN_SEARCH_TASKS = "MATRIXSEARCH_BEGIN_SEARCH_TASKS";

export const MATRIXSEARCH_END_SEARCH_TASKS = "MATRIXSEARCH_END_SEARCH_TASKS";

export const MATRIXSEARCH_END_SEARCH_TASKS_CHIBA = "MATRIXSEARCH_END_SEARCH_TASKS_CHIBA";

export const MATRIXSEARCH_END_SEARCH_TASKS_INFINITE = "MATRIXSEARCH_END_SEARCH_TASKS_INFINITE";

export const MATRIXSEARCH_BEGIN_SEARCH_PROGRESS_RATE = "MATRIXSEARCH_BEGIN_SEARCH_PROGRESS_RATE";

export const MATRIXSEARCH_END_SEARCH_PROGRESS_RATE = "MATRIXSEARCH_END_SEARCH_PROGRESS_RATE";

export const MATRIXSEARCH_BEGIN_FETCH_DOWNLOAD_URL = "MATRIXSEARCH_BEGIN_FETCH_DOWNLOAD_URL";

export const MATRIXSEARCH_END_FETCH_DOWNLOAD_URL = "MATRIXSEARCH_END_FETCH_DOWNLOAD_URL";

export const MATRIXSEARCH_SET_VALID_REQUIRED = "MATRIXSEARCH_SET_VALID_REQUIRED";

export const MATRIXSEARCH_REVERT_CONDITION = "MATRIXSEARCH_REVERT_CONDITION";

export const MATRIXSEARCH_CLEAR_CONDITIONS = "MATRIXSEARCH_CLEAR_CONDITIONS";

// List actions
export const LIST_BEGIN_FETCH_DATA = "LIST_BEGIN_FETCH_DATA";

export const LIST_END_FETCH_DATA = "LIST_END_FETCH_DATA";

export const LIST_TOGGLE_DELIVERY_FLG = "LIST_TOGGLE_DELIVERY_FLG";

export const LIST_SAVE_APPROVALS = "LIST_SAVE_APPROVALS";

export const LIST_SAVE_COMMENT = "LIST_SAVE_COMMENT";

export const LIST_CHANGE_SWITCHER_STATUS = "LIST_CHANGE_SWITCHER_STATUS";

export const LIST_BEGIN_CHANGE_TASK_STATUS = "LIST_BEGIN_CHANGE_TASK_STATUS";

export const LIST_END_CHANGE_TASK_STATUS = "LIST_END_CHANGE_TASK_STATUS";

export const LIST_ERROR_CHANGE_TASK_STATUS = "LIST_ERROR_CHANGE_TASK_STATUS";

// Qrcode Actions
export const QRCODE_CHANGE_AREA = "QRCODE_CHANGE_AREA";

export const QRCODE_CHANGE_DEVICE = "QRCODE_CHANGE_DEVICE";

export const QRCODE_CHANGE_CATEGORY = "QRCODE_CHANGE_CATEGORY";

export const QRCODE_CHANGE_FACILITY_MANAGEMENT = "QRCODE_CHANGE_FACILITY_MANAGEMENT";

export const QRCODE_CHANGE_CONSTRUCTION_MANAGEMENT = "QRCODE_CHANGE_CONSTRUCTION_MANAGEMENT";

export const QRCODE_CHANGE_PRIMARY_CHARGE = "QRCODE_CHANGE_PRIMARY_CHARGE";

export const QRCODE_CHANGE_OTHER = "QRCODE_CHANGE_OTHER";

export const QRCODE_CHANGE_COMPANY = "QRCODE_CHANGE_COMPANY";

export const QRCODE_CHANGE_USER = "QRCODE_CHANGE_USER";

export const QRCODE_CHANGE_GROUP = "QRCODE_CHANGE_GROUP";

export const QRCODE_BEGIN_SEARCH = "QRCODE_BEGIN_SEARCH";

export const QRCODE_END_SEARCH = "QRCODE_END_SEARCH";

export const QRCODE_BEGIN_SEARCH_INFINITE = "QRCODE_BEGIN_SEARCH_INFINITE";

export const QRCODE_END_SEARCH_INFINITE = "QRCODE_END_SEARCH_INFINITE";

export const QRCODE_CLEAR_SEARCH = "QRCODE_CLEAR_SEARCH";

export const QRCODE_REVERT_CONDITION = "QRCODE_REVERT_CONDITION";

export const DOWNLOAD_ZIP = "DOWNLOAD_ZIP";

export const QRCODE_SET_VALID_REQUIRED = "QRCODE_SET_VALID_REQUIRED";

// Asset Actions
export const ASSET_CHANGE_CATEGORY = "ASSET_CHANGE_CATEGORY";

export const ASSET_CHANGE_FILENAME = "ASSET_CHANGE_FILENAME";

export const ASSET_CHANGE_DATE_FROM = "ASSET_CHANGE_DATE_FROM";

export const ASSET_CHANGE_DATE_TO = "ASSET_CHANGE_DATE_TO";

export const ASSET_CHANGE_UPLOAD_TARGET = "ASSET_CHANGE_UPLOAD_TARGET";

export const ASSET_BEGIN_SEARCH = "ASSET_BEGIN_SEARCH";

export const ASSET_END_SEARCH = "ASSET_END_SEARCH";

export const ASSET_BEGIN_SEARCH_INFINITE = "ASSET_BEGIN_SEARCH_INFINITE";

export const ASSET_END_SEARCH_INFINITE = "ASSET_END_SEARCH_INFINITE";

export const ASSET_CLEAR_SEARCH = "ASSET_CLEAR_SEARCH";

export const ASSET_REVERT_CONDITION = "ASSET_REVERT_CONDITION";

export const ASSET_SET_VALIDATED = "ASSET_SET_VALIDATED";

export const ASSET_BEGIN_DELETE_ITEM = "ASSET_BEGIN_DELETE_ITEM";

export const ASSET_END_DELETE_ITEM = "ASSET_END_DELETE_ITEM";

export const ASSET_END_DOWNLOAD = "ASSET_END_DOWNLOAD";

export const ASSET_BEGIN_CREATE_ZIP_FILE = "ASSET_BEGIN_CREATE_ZIP_FILE";

export const ASSET_END_CREATE_ZIP_FILE = "ASSET_END_CREATE_ZIP_FILE";

// Category Actions
export const CATEGORY_BEGIN_FETCH_ITEMS = "CATEGORY_BEGIN_FETCH_ITEMS";

export const CATEGORY_END_FETCH_ITEMS = "CATEGORY_END_FETCH_ITEMS";

export const CATEGORY_CHANGE_SEARCH_TEXT = "CATEGORY_CHANGE_SEARCH_TEXT";

export const CATEGORY_BEGIN_SEARCH_ITEMS = "CATEGORY_BEGIN_SEARCH_ITEMS";

export const CATEGORY_END_SEARCH_ITEMS = "CATEGORY_END_SEARCH_ITEMS";

export const CATEGORY_END_FETCH_ITEM = "CATEGORY_END_FETCH_ITEM";

export const CATEGORY_BEGIN_DELETE_ITEM = "CATEGORY_BEGIN_DELETE_ITEM";

export const CATEGORY_END_DELETE_ITEM = "CATEGORY_END_DELETE_ITEM";

export const CATEGORY_END_UPDATE_ITEM = "CATEGORY_END_UPDATE_ITEM";

export const CATEGORY_END_CREATE_ITEM = "CATEGORY_END_CREATE_ITEM";

export const CATEGORY_END_MERGE_ITEM = "CATEGORY_END_MERGE_ITEM";

// Checkpoint Actions
export const CHECKPOINT_BEGIN_FETCH_ITEMS = "CHECKPOINT_BEGIN_FETCH_ITEMS";

export const CHECKPOINT_END_FETCH_ITEMS = "CHECKPOINT_END_FETCH_ITEMS";

export const CHECKPOINT_CHANGE_SEARCH_CATEGORY_ID = "CHECKPOINT_CHANGE_SEARCH_CATEGORY_ID";

export const CHECKPOINT_CHANGE_SEARCH_TEXT = "CHECKPOINT_CHANGE_SEARCH_TEXT";

export const CHECKPOINT_BEGIN_SEARCH_ITEMS = "CHECKPOINT_BEGIN_SEARCH_ITEMS";

export const CHECKPOINT_END_SEARCH_ITEMS = "CHECKPOINT_END_SEARCH_ITEMS";

export const CHECKPOINT_END_FETCH_ITEM = "CHECKPOINT_END_FETCH_ITEM";

export const CHECKPOINT_BEGIN_DELETE_ITEM = "CHECKPOINT_BEGIN_DELETE_ITEM";

export const CHECKPOINT_END_DELETE_ITEM = "CHECKPOINT_END_DELETE_ITEM";

export const CHECKPOINT_END_UPDATE_ITEM = "CHECKPOINT_END_UPDATE_ITEM";

export const CHECKPOINT_END_CREATE_ITEM = "CHECKPOINT_END_CREATE_ITEM";

// Area Actions
export const AREA_BEGIN_FETCH_ITEMS = "AREA_BEGIN_FETCH_ITEMS";

export const AREA_END_FETCH_ITEMS = "AREA_END_FETCH_ITEMS";

export const AREA_CHANGE_SEARCH_TEXT = "AREA_CHANGE_SEARCH_TEXT";

export const AREA_BEGIN_SEARCH_ITEMS = "AREA_BEGIN_SEARCH_ITEMS";

export const AREA_END_SEARCH_ITEMS = "AREA_END_SEARCH_ITEMS";

export const AREA_END_FETCH_ITEM = "AREA_END_FETCH_ITEM";

export const AREA_BEGIN_DELETE_ITEM = "AREA_BEGIN_DELETE_ITEM";

export const AREA_END_DELETE_ITEM = "AREA_END_DELETE_ITEM";

export const AREA_END_UPDATE_ITEM = "AREA_END_UPDATE_ITEM";

export const AREA_END_CREATE_ITEM = "AREA_END_CREATE_ITEM";

export const AREA_END_MERGE_ITEM = "AREA_END_MERGE_ITEM";

// Device Actions
export const DEVICE_BEGIN_FETCH_ITEMS = "DEVICE_BEGIN_FETCH_ITEMS";

export const DEVICE_END_FETCH_ITEMS = "DEVICE_END_FETCH_ITEMS";

export const DEVICE_CHANGE_SEARCH_TEXT = "DEVICE_CHANGE_SEARCH_TEXT";

export const DEVICE_BEGIN_SEARCH_ITEMS = "DEVICE_BEGIN_SEARCH_ITEMS";

export const DEVICE_END_SEARCH_ITEMS = "DEVICE_END_SEARCH_ITEMS";

export const DEVICE_END_FETCH_ITEM = "DEVICE_END_FETCH_ITEM";

export const DEVICE_BEGIN_DELETE_ITEM = "DEVICE_BEGIN_DELETE_ITEM";

export const DEVICE_END_DELETE_ITEM = "DEVICE_END_DELETE_ITEM";

export const DEVICE_END_UPDATE_ITEM = "DEVICE_END_UPDATE_ITEM";

export const DEVICE_END_CREATE_ITEM = "DEVICE_END_CREATE_ITEM";

export const DEVICE_END_MERGE_ITEM = "DEVICE_END_MERGE_ITEM";

// Facility_management Actions
export const FACILITY_MANAGEMENT_BEGIN_FETCH_ITEMS = "FACILITY_MANAGEMENT_BEGIN_FETCH_ITEMS";

export const FACILITY_MANAGEMENT_END_FETCH_ITEMS = "FACILITY_MANAGEMENT_END_FETCH_ITEMS";

export const FACILITY_MANAGEMENT_CHANGE_SEARCH_TEXT = "FACILITY_MANAGEMENT_CHANGE_SEARCH_TEXT";

export const FACILITY_MANAGEMENT_BEGIN_SEARCH_ITEMS = "FACILITY_MANAGEMENT_BEGIN_SEARCH_ITEMS";

export const FACILITY_MANAGEMENT_END_SEARCH_ITEMS = "FACILITY_MANAGEMENT_END_SEARCH_ITEMS";

export const FACILITY_MANAGEMENT_END_FETCH_ITEM = "FACILITY_MANAGEMENT_END_FETCH_ITEM";

export const FACILITY_MANAGEMENT_BEGIN_DELETE_ITEM = "FACILITY_MANAGEMENT_BEGIN_DELETE_ITEM";

export const FACILITY_MANAGEMENT_END_DELETE_ITEM = "FACILITY_MANAGEMENT_END_DELETE_ITEM";

export const FACILITY_MANAGEMENT_END_UPDATE_ITEM = "FACILITY_MANAGEMENT_END_UPDATE_ITEM";

export const FACILITY_MANAGEMENT_END_CREATE_ITEM = "FACILITY_MANAGEMENT_END_CREATE_ITEM";

export const FACILITY_MANAGEMENT_END_MERGE_ITEM = "FACILITY_MANAGEMENT_END_MERGE_ITEM";

// Construction_management Actions
export const CONSTRUCTION_MANAGEMENT_BEGIN_FETCH_ITEMS = "CONSTRUCTION_MANAGEMENT_BEGIN_FETCH_ITEMS";

export const CONSTRUCTION_MANAGEMENT_END_FETCH_ITEMS = "CONSTRUCTION_MANAGEMENT_END_FETCH_ITEMS";

export const CONSTRUCTION_MANAGEMENT_CHANGE_SEARCH_TEXT = "CONSTRUCTION_MANAGEMENT_CHANGE_SEARCH_TEXT";

export const CONSTRUCTION_MANAGEMENT_BEGIN_SEARCH_ITEMS = "CONSTRUCTION_MANAGEMENT_BEGIN_SEARCH_ITEMS";

export const CONSTRUCTION_MANAGEMENT_END_SEARCH_ITEMS = "CONSTRUCTION_MANAGEMENT_END_SEARCH_ITEMS";

export const CONSTRUCTION_MANAGEMENT_END_FETCH_ITEM = "CONSTRUCTION_MANAGEMENT_END_FETCH_ITEM";

export const CONSTRUCTION_MANAGEMENT_BEGIN_DELETE_ITEM = "CONSTRUCTION_MANAGEMENT_BEGIN_DELETE_ITEM";

export const CONSTRUCTION_MANAGEMENT_END_DELETE_ITEM = "CONSTRUCTION_MANAGEMENT_END_DELETE_ITEM";

export const CONSTRUCTION_MANAGEMENT_END_UPDATE_ITEM = "CONSTRUCTION_MANAGEMENT_END_UPDATE_ITEM";

export const CONSTRUCTION_MANAGEMENT_END_CREATE_ITEM = "CONSTRUCTION_MANAGEMENT_END_CREATE_ITEM";

export const CONSTRUCTION_MANAGEMENT_END_MERGE_ITEM = "CONSTRUCTION_MANAGEMENT_END_MERGE_ITEM";

// Primary_charge Actions
export const PRIMARY_CHARGE_BEGIN_FETCH_ITEMS = "PRIMARY_CHARGE_BEGIN_FETCH_ITEMS";

export const PRIMARY_CHARGE_END_FETCH_ITEMS = "PRIMARY_CHARGE_END_FETCH_ITEMS";

export const PRIMARY_CHARGE_CHANGE_SEARCH_TEXT = "PRIMARY_CHARGE_CHANGE_SEARCH_TEXT";

export const PRIMARY_CHARGE_BEGIN_SEARCH_ITEMS = "PRIMARY_CHARGE_BEGIN_SEARCH_ITEMS";

export const PRIMARY_CHARGE_END_SEARCH_ITEMS = "PRIMARY_CHARGE_END_SEARCH_ITEMS";

export const PRIMARY_CHARGE_END_FETCH_ITEM = "PRIMARY_CHARGE_END_FETCH_ITEM";

export const PRIMARY_CHARGE_BEGIN_DELETE_ITEM = "PRIMARY_CHARGE_BEGIN_DELETE_ITEM";

export const PRIMARY_CHARGE_END_DELETE_ITEM = "PRIMARY_CHARGE_END_DELETE_ITEM";

export const PRIMARY_CHARGE_END_UPDATE_ITEM = "PRIMARY_CHARGE_END_UPDATE_ITEM";

export const PRIMARY_CHARGE_END_CREATE_ITEM = "PRIMARY_CHARGE_END_CREATE_ITEM";

export const PRIMARY_CHARGE_END_MERGE_ITEM = "PRIMARY_CHARGE_END_MERGE_ITEM";

// Other Actions
export const OTHER_BEGIN_FETCH_ITEMS = "OTHER_BEGIN_FETCH_ITEMS";

export const OTHER_END_FETCH_ITEMS = "OTHER_END_FETCH_ITEMS";

export const OTHER_CHANGE_SEARCH_TEXT = "OTHER_CHANGE_SEARCH_TEXT";

export const OTHER_BEGIN_SEARCH_ITEMS = "OTHER_BEGIN_SEARCH_ITEMS";

export const OTHER_END_SEARCH_ITEMS = "OTHER_END_SEARCH_ITEMS";

export const OTHER_END_FETCH_ITEM = "OTHER_END_FETCH_ITEM";

export const OTHER_BEGIN_DELETE_ITEM = "OTHER_BEGIN_DELETE_ITEM";

export const OTHER_END_DELETE_ITEM = "OTHER_END_DELETE_ITEM";

export const OTHER_END_UPDATE_ITEM = "OTHER_END_UPDATE_ITEM";

export const OTHER_END_CREATE_ITEM = "OTHER_END_CREATE_ITEM";

export const OTHER_END_MERGE_ITEM = "OTHER_END_MERGE_ITEM";

// Process_major_class Actions
export const PROCESS_MAJOR_CLASS_BEGIN_FETCH_ITEMS = "PROCESS_MAJOR_CLASS_BEGIN_FETCH_ITEMS";

export const PROCESS_MAJOR_CLASS_END_FETCH_ITEMS = "PROCESS_MAJOR_CLASS_END_FETCH_ITEMS";

export const PROCESS_MAJOR_CLASS_CHANGE_SEARCH_TEXT = "PROCESS_MAJOR_CLASS_CHANGE_SEARCH_TEXT";

export const PROCESS_MAJOR_CLASS_BEGIN_SEARCH_ITEMS = "PROCESS_MAJOR_CLASS_BEGIN_SEARCH_ITEMS";

export const PROCESS_MAJOR_CLASS_END_SEARCH_ITEMS = "PROCESS_MAJOR_CLASS_END_SEARCH_ITEMS";

export const PROCESS_MAJOR_CLASS_END_FETCH_ITEM = "PROCESS_MAJOR_CLASS_END_FETCH_ITEM";

export const PROCESS_MAJOR_CLASS_BEGIN_DELETE_ITEM = "PROCESS_MAJOR_CLASS_BEGIN_DELETE_ITEM";

export const PROCESS_MAJOR_CLASS_END_DELETE_ITEM = "PROCESS_MAJOR_CLASS_END_DELETE_ITEM";

export const PROCESS_MAJOR_CLASS_END_UPDATE_ITEM = "PROCESS_MAJOR_CLASS_END_UPDATE_ITEM";

export const PROCESS_MAJOR_CLASS_END_CREATE_ITEM = "PROCESS_MAJOR_CLASS_END_CREATE_ITEM";

export const PROCESS_MAJOR_CLASS_END_MERGE_ITEM = "PROCESS_MAJOR_CLASS_END_MERGE_ITEM";

// Process_middle_class Actions
export const PROCESS_MIDDLE_CLASS_BEGIN_FETCH_ITEMS = "PROCESS_MIDDLE_CLASS_BEGIN_FETCH_ITEMS";

export const PROCESS_MIDDLE_CLASS_END_FETCH_ITEMS = "PROCESS_MIDDLE_CLASS_END_FETCH_ITEMS";

export const PROCESS_MIDDLE_CLASS_CHANGE_SEARCH_TEXT = "PROCESS_MIDDLE_CLASS_CHANGE_SEARCH_TEXT";

export const PROCESS_MIDDLE_CLASS_BEGIN_SEARCH_ITEMS = "PROCESS_MIDDLE_CLASS_BEGIN_SEARCH_ITEMS";

export const PROCESS_MIDDLE_CLASS_END_SEARCH_ITEMS = "PROCESS_MIDDLE_CLASS_END_SEARCH_ITEMS";

export const PROCESS_MIDDLE_CLASS_END_FETCH_ITEM = "PROCESS_MIDDLE_CLASS_END_FETCH_ITEM";

export const PROCESS_MIDDLE_CLASS_BEGIN_DELETE_ITEM = "PROCESS_MIDDLE_CLASS_BEGIN_DELETE_ITEM";

export const PROCESS_MIDDLE_CLASS_END_DELETE_ITEM = "PROCESS_MIDDLE_CLASS_END_DELETE_ITEM";

export const PROCESS_MIDDLE_CLASS_END_UPDATE_ITEM = "PROCESS_MIDDLE_CLASS_END_UPDATE_ITEM";

export const PROCESS_MIDDLE_CLASS_END_CREATE_ITEM = "PROCESS_MIDDLE_CLASS_END_CREATE_ITEM";

export const PROCESS_MIDDLE_CLASS_END_MERGE_ITEM = "PROCESS_MIDDLE_CLASS_END_MERGE_ITEM";

// Import
export const IMPORT_BEGIN_FETCH_ITEMS = "IMPORT_BEGIN_FETCH_ITEMS";

export const IMPORT_END_FETCH_ITEMS = "IMPORT_END_FETCH_ITEMS";

export const IMPORT_CHANGE_SEARCH_TEXT = "IMPORT_CHANGE_SEARCH_TEXT";

export const IMPORT_BEGIN_SEARCH_ITEMS = "IMPORT_BEGIN_SEARCH_ITEMS";

export const IMPORT_END_SEARCH_ITEMS = "IMPORT_END_SEARCH_ITEMS";

export const IMPORT_END_FETCH_ITEM = "IMPORT_END_FETCH_ITEM";

export const IMPORT_CHANGE_CATEGORY = "IMPORT_CHANGE_CATEGORY";

export const IMPORT_CHANGE_COMPANY = "IMPORT_CHANGE_COMPANY";

export const IMPORT_CHANGE_USER = "IMPORT_CHANGE_USER";

export const IMPORT_CHANGE_FILENAME = "IMPORT_CHANGE_FILENAME";

export const IMPORT_CHANGE_STATUS = "IMPORT_CHANGE_STATUS";

export const IMPORT_CHANGE_FILETYPE = "IMPORT_CHANGE_FILETYPE";

export const IMPORT_CHANGE_DATE_FROM = "IMPORT_CHANGE_DATE_FROM";

export const IMPORT_CHANGE_DATE_TO = "IMPORT_CHANGE_DATE_TO";

export const IMPORT_RESET_SEARCH = "IMPORT_RESET_SEARCH";

export const IMPORT_END_ERRORS_ITEM = "IMPORT_END_ERRORS_ITEM";

export const IMPORT_END_UPLOAD_FILE = "IMPORT_END_UPLOAD_FILE";

export const IMPORT_IMPORT_FILETYPE = "IMPORT_IMPORT_FILETYPE";

export const IMPORT_REVERT_CONDITION = "IMPORT_REVERT_CONDITION";

export const IMPORT_SET_VALIDATED = "IMPORT_SET_VALIDATED";

export const IMPORT_TOGGLE_DOWNLOADING = "IMPORT_TOGGLE_DOWNLOADING";

export const IMPORT_ERROR_400 = "IMPORT_ERROR_400";

// System
export const SYSTEM_BEGIN_FETCH_ITEMS = "SYSTEM_BEGIN_FETCH_ITEMS";

export const SYSTEM_END_FETCH_ITEMS = "SYSTEM_END_FETCH_ITEMS";

export const SYSTEM_BEGIN_FETCH_ITEM = "SYSTEM_BEGIN_FETCH_ITEM";

export const SYSTEM_END_FETCH_ITEM = "SYSTEM_END_FETCH_ITEM";

export const SYSTEM_CHANGE_SEARCH_TEXT = "SYSTEM_CHANGE_SEARCH_TEXT";

export const SYSTEM_BEGIN_SEARCH_ITEMS = "SYSTEM_BEGIN_SEARCH_ITEMS";

export const SYSTEM_END_SEARCH_ITEMS = "SYSTEM_END_SEARCH_ITEMS";

export const SYSTEM_BEGIN_SEARCH_DETAIL_ITEMS = "SYSTEM_BEGIN_SEARCH_DETAIL_ITEMS";

export const SYSTEM_END_SEARCH_DETAIL_ITEMS = "SYSTEM_END_SEARCH_DETAIL_ITEMS";

export const SYSTEM_BEGIN_SEARCH_DETAIL_ITEMS_INFINITE = "SYSTEM_BEGIN_SEARCH_DETAIL_ITEMS_INFINITE";

export const SYSTEM_END_SEARCH_DETAIL_ITEMS_INFINITE = "SYSTEM_END_SEARCH_DETAIL_ITEMS_INFINITE";

export const SYSTEM_END_UPDATE_ITEM = "SYSTEM_END_UPDATE_ITEM";

export const SYSTEM_CHANGE_AREA = "SYSTEM_CHANGE_AREA";

export const SYSTEM_CHANGE_DEVICE = "SYSTEM_CHANGE_DEVICE";

export const SYSTEM_CHANGE_FACILITY_MANAGEMENT = "SYSTEM_CHANGE_FACILITY_MANAGEMENT";

export const SYSTEM_CHANGE_SYSTEMNAME = "SYSTEM_CHANGE_SYSTENAME";

export const SYSTEM_CHANGE_NOTE = "SYSTEM_CHANGE_NOTE";

export const SYSTEM_CHANGE_SCHEDULED_DATE_FROM = "SYSTEM_CHANGE_SCHEDULED_DATE_FROM";

export const SYSTEM_CHANGE_SCHEDULED_DATE_TO = "SYSTEM_CHANGE_SCHEDULED_DATE_TO";

export const SYSTEM_CHANGE_CONSTRUCTION_MANAGEMENT = "SYSTEM_CHANGE_CONSTRUCTION_MANAGEMENT";

export const SYSTEM_CHANGE_PRIMARY_CHARGE = "SYSTEM_CHANGE_PRIMARY_CHARGE";

export const SYSTEM_CHANGE_OTHER = "SYSTEM_CHANGE_OTHER";

export const SYSTEM_CHANGE_CLOSE_DATE_FROM = "SYSTEM_CHANGE_CLOSE_DATE_FROM";

export const SYSTEM_CHANGE_CLOSE_DATE_TO = "SYSTEM_CHANGE_CLOSE_DATE_TO";

export const SYSTEM_CHANGE_CATEGORY = "SYSTEM_CHANGE_CATEGORY";

export const SYSTEM_CHANGE_TOTALTEST = "SYSTEM_CHANGE_TOTALTEST";

export const SYSTEM_CHANGE_PREPARATION = "SYSTEM_CHANGE_PREPARATION";

export const SYSTEM_RESET_SEARCH = "SYSTEM_RESET_SEARCH";

export const SYSTEM_REVERT_CONDITION = "SYSTEM_REVERT_CONDITION";

export const SYSTEM_SET_VALIDATED = "SYSTEM_SET_VALIDATED";

export const SYSTEM_TOGGLE_DOWNLOADING = "SYSTEM_TOGGLE_DOWNLOADING";

export const PROGRESS_NOTIFICATIONS_BEGIN_FETCH_NOTIFICATIONS = "PROGRESS_NOTIFICATIONS_BEGIN_FETCH_NOTIFICATIONS";

export const PROGRESS_NOTIFICATIONS_END_FETCH_NOTIFICATIONS = "PROGRESS_NOTIFICATIONS_END_FETCH_NOTIFICATIONS";

export const PROGRESS_NOTIFICATIONS_BEGIN_READ_NOTIFICATION = "PROGRESS_NOTIFICATIONS_BEGIN_READ_NOTIFICATION";

export const PROGRESS_NOTIFICATIONS_END_READ_NOTIFICATION = "PROGRESS_NOTIFICATIONS_END_READ_NOTIFICATION";

export const PROGRESS_NOTIFICATIONS_BEGIN_READ_ALL_NOTIFICATION = "PROGRESS_NOTIFICATIONS_BEGIN_READ_ALL_NOTIFICATION";

export const PROGRESS_NOTIFICATIONS_END_READ_ALL_NOTIFICATION = "PROGRESS_NOTIFICATIONS_END_READ_ALL_NOTIFICATION";

export const PROGRESS_NOTIFICATIONS_REFRESH = "PROGRESS_NOTIFICATIONS_REFRESH";

export const NOTIFICATIONS_BEGIN_FETCH_SUMMARY = "NOTIFICATIONS_BEGIN_FETCH_SUMMARY";

export const NOTIFICATIONS_END_FETCH_SUMMARY = "NOTIFICATIONS_END_FETCH_SUMMARY";

// Data Export
export const DATAEXPORT_BEGIN_FETCH_JOBS = "DATAEXPORT_BEGIN_FETCH_JOBS";

export const DATAEXPORT_END_FETCH_JOBS = "DATAEXPORT_END_FETCH_JOBS";

export const DATAEXPORT_BEGIN_DOWNLOAD_JOBS = "DATAEXPORT_BEGIN_DOWNLOAD_JOBS";

export const DATAEXPORT_END_DOWNLOAD_JOBS = "DATAEXPORT_END_DOWNLOAD_JOBS";

export const DATAEXPORT_BEGIN_CANCEL_JOBS = "DATAEXPORT_BEGIN_CANCEL_JOBS";

export const DATAEXPORT_END_CANCEL_JOBS = "DATAEXPORT_END_CANCEL_JOBS";

export const DATAEXPORT_BEGIN_DONWLOAD_FILE = "DATAEXPORT_BEGIN_DONWLOAD_FILE";

export const DATAEXPORT_END_DONWLOAD_FILE = "DATAEXPORT_END_DONWLOAD_FILE";

export const DATAEXPORT_CLEAR_JOBS = "DATAEXPORT_CLEAR_JOBS";

export const DATAEXPORT_BEGIN_READ_JOB = "DATAEXPORT_BEGIN_READ_JOB";

export const DATAEXPORT_END_READ_JOB = "DATAEXPORT_END_READ_JOB";

export const DATAEXPORT_BEGIN_READ_ALL_JOB = "DATAEXPORT_BEGIN_READ_ALL_JOB";

export const DATAEXPORT_END_READ_ALL_JOB = "DATAEXPORT_END_READ_ALL_JOB";

// For SX LAYOUT
export const LAYOUT_APP_CHANGE_MENU = "LAYOUT_APP_CHANGE_MENU";

// Plot Map
export const PLOT_MAP_SELECT_PLOT_PLAN = "PLOT_MAP_SELECT_PLOT_PLAN";

export const PLOT_MAP_BEGIN_FETCH_MAP_IMAGE = "PLOT_MAP_BEGIN_FETCH_MAP_IMAGE";

export const PLOT_MAP_END_FETCH_MAP_IMAGE = "PLOT_MAP_END_FETCH_MAP_IMAGE";

export const PLOT_MAP_BEGIN_FETCH_MACHINES = "PLOT_MAP_BEGIN_FETCH_MACHINES";

export const PLOT_MAP_END_FETCH_MACHINES = "PLOT_MAP_END_FETCH_MACHINES";

export const PLOT_MAP_BEGIN_FETCH_KEEPOUT_AREAS = "PLOT_MAP_BEGIN_FETCH_KEEPOUT_AREAS";

export const PLOT_MAP_END_FETCH_KEEPOUT_AREAS = "PLOT_MAP_END_FETCH_KEEPOUT_AREAS";

export const PLOT_MAP_BEGIN_FETCH_ARROWS = "PLOT_MAP_BEGIN_FETCH_ARROWS";

export const PLOT_MAP_END_FETCH_ARROWS = "PLOT_MAP_END_FETCH_ARROWS";

export const PLOT_MAP_BEGIN_FETCH_TEXTS = "PLOT_MAP_BEGIN_FETCH_TEXTS";

export const PLOT_MAP_END_FETCH_TEXTS = "PLOT_MAP_END_FETCH_TEXTS";

export const PLOT_MAP_SET_LAYOUT_DATE = "PLOT_MAP_SET_LAYOUT_DATE";

// PlotPlanSetting
export const PLOT_PLAN_BEGIN_FETCH_PLOT_PLANS = "PLOT_PLAN_BEGIN_FETCH_PLOT_PLANS";

export const PLOT_PLAN_END_FETCH_PLOT_PLANS = "PLOT_PLAN_END_FETCH_PLOT_PLANS";

export const PLOT_PLAN_BEGIN_CREATE_PLOT_PLAN = "PLOT_PLAN_BEGIN_CREATE_PLOT_PLAN";

export const PLOT_PLAN_END_CREATE_PLOT_PLAN = "PLOT_PLAN_END_CREATE_PLOT_PLAN";

export const PLOT_PLAN_BEGIN_UPDATE_PLOT_PLAN = "PLOT_PLAN_BEGIN_UPDATE_PLOT_PLAN";

export const PLOT_PLAN_END_UPDATE_PLOT_PLAN = "PLOT_PLAN_END_UPDATE_PLOT_PLAN";

export const PLOT_PLAN_BEGIN_FETCH_PLOT_PLAN = "PLOT_PLAN_BEGIN_FETCH_PLOT_PLAN";

export const PLOT_PLAN_END_FETCH_PLOT_PLAN = "PLOT_PLAN_END_FETCH_PLOT_PLAN";

export const PLOT_PLAN_BEGIN_DELETE_PLOT_PLAN = "PLOT_PLAN_BEGIN_DELETE_PLOT_PLAN";

export const PLOT_PLAN_END_DELETE_PLOT_PLAN = "PLOT_PLAN_END_DELETE_PLOT_PLAN";

// MachineSettings
export const MACHINE_BEGIN_FETCH_MACHINE_TYPES = "MACHINE_BEGIN_FETCH_MACHINE_TYPES";

export const MACHINE_END_FETCH_MACHINE_TYPES = "MACHINE_END_FETCH_MACHINE_TYPES";

export const MACHINE_BEGIN_FETCH_COMPANIES_MACHINE_TYPES = "MACHINE_BEGIN_FETCH_COMPANIES_MACHINE_TYPES";

export const MACHINE_END_FETCH_COMPANIES_MACHINE_TYPES = "MACHINE_END_FETCH_COMPANIES_MACHINE_TYPES";

export const MACHINE_BEGIN_UPDATE_COMPANIES_MACHINE_TYPES = "MACHINE_BEGIN_UPDATE_COMPANIES_MACHINE_TYPES";

export const MACHINE_END_UPDATE_COMPANIES_MACHINE_TYPES = "MACHINE_END_UPDATE_COMPANIES_MACHINE_TYPES";

// Machine and KeepOutArea
export const MACHINE_KEEPOUT_AREA_BEGIN_UPDATE_MACHINE_COORDINATES =
  "MACHINE_KEEPOUT_AREA_BEGIN_UPDATE_MACHINE_COORDINATES";

export const MACHINE_KEEPOUT_AREA_END_UPDATE_MACHINE_COORDINATES =
  "MACHINE_KEEPOUT_AREA_END_UPDATE_MACHINE_COORDINATES";

// Machine
export const MACHINE_BEGIN_FETCH_MACHINE = "MACHINE_BEGIN_FETCH_MACHINE";

export const MACHINE_END_FETCH_MACHINE = "MACHINE_END_FETCH_MACHINE";

export const MACHINE_BEGIN_COPY_MACHINE = "MACHINE_BEGIN_COPY_MACHINE";

export const MACHINE_END_COPY_MACHINE = "MACHINE_END_COPY_MACHINE";

export const MACHINE_BEGIN_CREATE_MACHINE = "MACHINE_BEGIN_CREATE_MACHINE";

export const MACHINE_END_CREATE_MACHINE = "MACHINE_END_CREATE_MACHINE";

export const MACHINE_BEGIN_UPDATE_MACHINE = "MACHINE_BEGIN_UPDATE_MACHINE";

export const MACHINE_END_UPDATE_MACHINE = "MACHINE_END_UPDATE_MACHINE";

export const MACHINE_BEGIN_UPDATE_MACHINE_COORDINATES = "MACHINE_BEGIN_UPDATE_MACHINE_COORDINATES";

export const MACHINE_END_UPDATE_MACHINE_COORDINATES = "MACHINE_END_UPDATE_MACHINE_COORDINATES";

export const MACHINE_BEGIN_UPDATE_MACHINE_STATUS = "MACHINE_BEGIN_UPDATE_MACHINE_STATUS";

export const MACHINE_END_UPDATE_MACHINE_STATUS = "MACHINE_END_UPDATE_MACHINE_STATUS";

export const MACHINE_BEGIN_DELETE_MACHINE = "MACHINE_BEGIN_DELETE_MACHINE";

export const MACHINE_END_DELETE_MACHINE = "MACHINE_END_DELETE_MACHINE";

// Layout Masters
export const LAYOUT_MASTERS_BEGIN_FETCH_MASTERS = "LAYOUT_MASTERS_BEGIN_FETCH_MASTERS";

export const LAYOUT_MASTERS_END_FETCH_MASTERS = "LAYOUT_MASTERS_END_FETCH_MASTERS";

// Notification
export const LAYOUT_NOTIFICATION_BEGIN_FETCH_NOTIFICATIONS = "LAYOUT_NOTIFICATION_BEGIN_FETCH_NOTIFICATIONS";

export const LAYOUT_NOTIFICATION_END_FETCH_NOTIFICATIONS = "LAYOUT_NOTIFICATION_END_FETCH_NOTIFICATIONS";

export const LAYOUT_NOTIFICATION_BEGIN_READ_NOTIFICATION = "LAYOUT_NOTIFICATION_BEGIN_READ_NOTIFICATION";

export const LAYOUT_NOTIFICATION_END_READ_NOTIFICATION = "LAYOUT_NOTIFICATION_END_READ_NOTIFICATION";

export const LAYOUT_NOTIFICATION_CLEAR_NOTIFICATIONS = "LAYOUT_NOTIFICATION_CLEAR_NOTIFICATIONS";

// Keep Out Areas
export const PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS = "PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS";

export const PLOT_MAP_END_CREATE_KEEP_OUT_AREAS = "PLOT_MAP_END_CREATE_KEEP_OUT_AREAS";

export const PLOT_MAP_BEGIN_COPY_KEEP_OUT_AREAS = "PLOT_MAP_BEGIN_COPY_KEEP_OUT_AREAS";

export const PLOT_MAP_END_COPY_KEEP_OUT_AREAS = "PLOT_MAP_END_COPY_KEEP_OUT_AREAS";

export const PLOT_MAP_BEGIN_DELETE_KEEP_OUT_AREAS = "PLOT_MAP_BEGIN_DELETE_KEEP_OUT_AREAS";

export const PLOT_MAP_END_DELETE_KEEP_OUT_AREAS = "PLOT_MAP_END_DELETE_KEEP_OUT_AREAS";

export const PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS = "PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS";

export const PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS = "PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS";

export const PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS_POLYGON = "PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS_POLYGON";

export const PLOT_MAP_END_CREATE_KEEP_OUT_AREAS_POLYGON = "PLOT_MAP_END_CREATE_KEEP_OUT_AREAS_POLYGON";

export const PLOT_MAP_ADD_WIP_KEEP_OUT_AREA_POLYGON = "PLOT_MAP_ADD_WIP_KEEP_OUT_AREA_POLYGON";

export const PLOT_MAP_CHANGE_WIP_KEEP_OUT_AREA_POLYGON_COMPANY = "PLOT_MAP_CHANGE_WIP_KEEP_OUT_AREA_POLYGON_COMPANY";

export const PLOT_MAP_REMOVE_WIP_KEEP_OUT_AREA_POLYGON = "PLOT_MAP_REMOVE_WIP_KEEP_OUT_AREA_POLYGON";

export const PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_POLYGON_COORDINATES =
  "PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_POLYGON_COORDINATES";

export const PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_POLYGON_COORDINATES =
  "PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_POLYGON_COORDINATES";

export const PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_COORDINATES = "PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_COORDINATES";

export const PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_COORDINATES = "PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_COORDINATES";

// Arrows
export const PLOT_MAP_BEGIN_CREATE_ARROWS = "PLOT_MAP_BEGIN_CREATE_ARROWS";

export const PLOT_MAP_END_CREATE_ARROWS = "PLOT_MAP_END_CREATE_ARROWS";

export const PLOT_MAP_BEGIN_DELETE_ARROWS = "PLOT_MAP_BEGIN_DELETE_ARROWS";

export const PLOT_MAP_END_DELETE_ARROWS = "PLOT_MAP_END_DELETE_ARROWS";

export const PLOT_MAP_BEGIN_UPDATE_ARROWS = "PLOT_MAP_BEGIN_UPDATE_ARROWS";

export const PLOT_MAP_END_UPDATE_ARROWS = "PLOT_MAP_END_UPDATE_ARROWS";

export const PLOT_MAP_BEGIN_UPDATE_ARROW_COORDINATES = "PLOT_MAP_BEGIN_UPDATE_ARROW_COORDINATES";

export const PLOT_MAP_END_UPDATE_ARROW_COORDINATES = "PLOT_MAP_END_UPDATE_ARROW_COORDINATES";

export const PLOT_MAP_BEGIN_COPY_ARROWS = "PLOT_MAP_BEGIN_COPY_ARROWS";

export const PLOT_MAP_END_COPY_ARROWS = "PLOT_MAP_END_COPY_ARROWS";

// Texts
export const PLOT_MAP_BEGIN_CREATE_TEXTS = "PLOT_MAP_BEGIN_CREATE_TEXTS";

export const PLOT_MAP_END_CREATE_TEXTS = "PLOT_MAP_END_CREATE_TEXTS";

export const PLOT_MAP_BEGIN_DELETE_TEXTS = "PLOT_MAP_BEGIN_DELETE_TEXTS";

export const PLOT_MAP_END_DELETE_TEXTS = "PLOT_MAP_END_DELETE_TEXTS";

export const PLOT_MAP_BEGIN_UPDATE_TEXTS = "PLOT_MAP_BEGIN_UPDATE_TEXTS";

export const PLOT_MAP_END_UPDATE_TEXTS = "PLOT_MAP_END_UPDATE_TEXTS";

export const PLOT_MAP_BEGIN_UPDATE_TEXT_COORDINATE = "PLOT_MAP_BEGIN_UPDATE_TEXT_COORDINATE";

export const PLOT_MAP_END_UPDATE_TEXT_COORDINATE = "PLOT_MAP_END_UPDATE_TEXT_COORDINATE";

export const PLOT_MAP_BEGIN_COPY_TEXTS = "PLOT_MAP_BEGIN_COPY_TEXTS";

export const PLOT_MAP_END_COPY_TEXTS = "PLOT_MAP_END_COPY_TEXTS";

// Layout File Download
export const LAYOUT_FILE_START_DOWNLOAD = "LAYOUT_FILE_START_DOWNLOAD";

export const LAYOUT_FILE_END_DOWNLOAD = "LAYOUT_FILE_END_DOWNLOAD";

// 全休日
export const UNWORK_DAYS_BEGIN_FETCH = "UNWORK_DAYS_BEGIN_FETCH";

export const UNWORK_DAYS_END_FETCH = "UNWORK_DAYS_BEGIN_FETCH";

export const UNWORK_DAYS_START_LOADING = "UNWORK_DAYS_START_LOADING";

export const UNWORK_DAYS_END_LOADING = "UNWORK_DAYS_END_LOADING";

export const UNWORK_DAYS_BEGIN_UPDATE = "UNWORK_DAYS_BEGIN_UPDATE";

export const UNWORK_DAYS_END_UPDATE = "UNWORK_DAYS_END_UPDATE";

export const HOLIDAY_EXPAND_ROW = "HOLIDAY_EXPAND_ROW";
