import React, { FC, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import actions from "@/actions";
import { SubmitButton } from "@/components/common/SubmitButton";
import { ScheduleUrl } from "@/models/scheduleChiba";

type Props = {
  scheduleUrl: ScheduleUrl;
  onUpdateUrl: (url: ScheduleUrl) => void;
  onClose: () => void;
};

export const EditScheduleUrlDialog: FC<Props> = ({ scheduleUrl, onUpdateUrl, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [label, setLabel] = useState<string>(scheduleUrl.label);
  const [labelError, setLabelError] = useState<string>(null);
  const [url, setUrl] = useState(scheduleUrl.url);
  const [urlError, setUrlError] = useState<string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    let isError = false;
    if (!label) {
      isError = true;
      setLabelError(t("enter_link_name"));
    } else if (100 < label.length) {
      isError = true;
      setLabelError(t("link_name") + t("is_too_long"));
    }
    if (!url) {
      isError = true;
      setUrlError(t("enter_link_url"));
    } else if (100 < url.length) {
      isError = true;
      setUrlError(t("link_url") + t("is_too_long"));
    } else if (!/^https?:\/\//.test(url)) {
      isError = true;
      setUrlError(t("enter_valid_url"));
    }
    if (isError) return;

    setLoading(true);
    setLabelError(null);
    setUrlError(null);

    dispatch(
      actions.schedule_chiba.updateScheduleUrl(
        scheduleUrl.item_url_id,
        { label, url, timestamp: scheduleUrl.timestamp },
        (res) => {
          onUpdateUrl(res);
          onClose();
          setLoading(false);
        },
        () => setLoading(false)
      )
    );
  };

  return (
    <div className="modal">
      <Draggable handle=".modal-header">
        <div className="modal-drag-container">
          <div className="modal-dialog">
            <div className={"modal-content"}>
              <div className="modal-header">
                <h3 className="modal-ttl">{t("edit_link")}</h3>
                <div className="btn-close" onClick={onClose}>
                  <span className="icon icon-close"></span>
                </div>
              </div>
              <div className="modal-body">
                <div className="form-row">
                  <div className="form-group txt-center w-[388px]">
                    <span className="form-label">{t("link_name")}</span>
                    <input
                      type="text"
                      className="form-control w-300"
                      value={label}
                      onChange={(e) => setLabel(e.target.value)}
                    />
                  </div>
                  {labelError && (
                    <div className="form-error w-360">
                      <p className="form-error-message w-280">{labelError}</p>
                    </div>
                  )}
                </div>
                <div className="form-row" style={{ marginBottom: 0 }}>
                  <div className="form-group txt-center">
                    <span className="form-label">{t("link_url")}</span>
                    <input
                      type="text"
                      className="form-control w-300"
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                  </div>
                  {urlError && (
                    <div className="form-error w-360">
                      <p className="form-error-message w-280">{urlError}</p>
                    </div>
                  )}
                </div>
              </div>
              <div className={"modal-footer"}>
                <button
                  data-test-id="button-column-editor-cancel"
                  type="button"
                  className="btn btn-gray"
                  onClick={onClose}
                >
                  {t("cancel")}
                </button>
                <SubmitButton onClick={handleSubmit} loading={loading} />
              </div>
            </div>
          </div>
        </div>
      </Draggable>
    </div>
  );
};
