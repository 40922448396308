import * as constants from "@/constants";
import storageManager from "@/lib/storageManager";
import { TimeStamp } from "@/models/timestamp";
import {
  UpdateCustomKeepOutAreasCoordinatesParams,
  UpdateKeepOutAreasCoordinatesResponse,
  UpdateMachineResponse,
  UpdateMachineStatusResponse,
} from "@/sx-layout/components/plotmap/actions/types";
import { FreeDrawArrow, FreeDrawText } from "@/sx-layout/components/plotmap/components/freeDraw/models";
import {
  Machine,
  KeepOutArea,
  KeepOutAreaPolygon,
  KeepOutAreaRectangle,
  filterAreaRectangle,
  filterAreaPolygon,
} from "@/sx-layout/components/plotmap/models";

export type PlotMapState = {
  plotPlanId: string;
  mapImage: Blob;
  machines: Machine[];
  keepOutAreas: KeepOutAreaRectangle[];
  keepOutAreasPolygon: KeepOutAreaPolygon[];
  wipKeepOutAreaPolygon?: KeepOutAreaPolygon;
  arrows: FreeDrawArrow[];
  texts: FreeDrawText[];
  layoutDate: Date;
  fetching: boolean;
};

const afterDeleteMachine = (machine_id: string, machines: Machine[]) => {
  return machines.filter((m) => m.machine_id !== Number(machine_id));
};

const afterUpdateMachine = (response: UpdateMachineResponse, machines: Machine[]) => {
  return machines.map((m) => {
    if (m.machine_id === Number(response.machine_id)) {
      return { ...m, ...response, machine_id: Number(response.machine_id) };
    }

    return m;
  });
};

const afterUpdateMachineCoordinates = (
  machine: {
    machine_id: string;
    timestamp: TimeStamp;
    x: number;
    y: number;
  },
  machines: Machine[]
) => {
  return machines.map((m) => {
    if (m.machine_id === Number(machine.machine_id)) {
      return { ...m, ...machine, machine_id: Number(machine.machine_id) };
    }

    return m;
  });
};

const afterUpdateMachineStatus = (machine: UpdateMachineStatusResponse, machines: Machine[]) => {
  return machines.map((m) => {
    if (m.machine_id === Number(machine.machine_id)) {
      return { ...m, ...machine, machine_id: Number(machine.machine_id) };
    }

    return m;
  });
};

const afterDeleteKeepOutAreas = (keepout_area_id: string, keepOutAreas: KeepOutArea[]) => {
  return keepOutAreas.filter((area) => area.keepout_area_id !== Number(keepout_area_id));
};

const afterDeleteKeepOutAreasWithMachine = (machine_id: string, machines: Machine[]) => {
  return machines.map((m) => {
    if (m.machine_id === Number(machine_id)) {
      return { ...m, keepout_area_id: null };
    }

    return m;
  });
};

// 重機に紐づく立入禁止エリアの作成後に重機にエリアIDを反映
const afterCreateKeepOutAreas = (response: KeepOutArea, machines: Machine[]) => {
  if (!response.machine_id) {
    return machines;
  }

  return machines.map((m) => {
    if (m.machine_id === Number(response.machine_id)) {
      return {
        ...m,
        keepout_area_id: Number(response.keepout_area_id),
      };
    }

    return m;
  });
};

const afterUpdateKeepOutAreas = (response: KeepOutArea, keepOutAreas: KeepOutArea[]) => {
  return keepOutAreas.map((area) => {
    if (area.keepout_area_id === Number(response.keepout_area_id)) {
      return {
        ...area,
        ...response,
        keepout_area_id: Number(response.keepout_area_id),
      };
    }

    return area;
  });
};

const afterUpdateKeepOutAreasCoordinates = (
  keepOutArea: {
    keepout_area_id: string;
    timestamp: TimeStamp;
    x: number;
    y: number;
    w: number;
    h: number;
  },
  keepOutAreas: KeepOutArea[]
) => {
  return keepOutAreas.map((area) => {
    if (area.keepout_area_id === Number(keepOutArea.keepout_area_id)) {
      return {
        ...area,
        ...keepOutArea,
        keepout_area_id: Number(keepOutArea.keepout_area_id),
      };
    }

    return area;
  });
};

export const plotmap = (
  state: PlotMapState = {
    plotPlanId: storageManager.getPlotPlanId() ?? "",
    mapImage: null,
    machines: [],
    keepOutAreas: [],
    keepOutAreasPolygon: [],
    arrows: [],
    texts: [],
    layoutDate: storageManager.getLayoutDate(),
    fetching: false,
  },
  action
) => {
  switch (action.type) {
    case constants.LOGIN_SUCCESS: {
      return {
        ...state,
        plotPlanId: storageManager.getPlotPlanId() ?? "",
      };
    }
    case constants.PLOT_MAP_SELECT_PLOT_PLAN: {
      return {
        ...state,
        plotPlanId: action.payload,
      };
    }
    // fall-through
    case constants.PLOT_MAP_BEGIN_FETCH_MACHINES:
      return {
        ...state,
        fetching: true,
        machines: [],
      };
    case constants.PLOT_MAP_BEGIN_FETCH_KEEPOUT_AREAS:
      return {
        ...state,
        fetching: true,
        keepOutAreas: [],
        keepOutAreasPolygon: [],
      };
    case constants.PLOT_MAP_BEGIN_FETCH_ARROWS:
      return {
        ...state,
        fetching: true,
        arrows: [],
      };
    case constants.PLOT_MAP_BEGIN_FETCH_TEXTS:
      return {
        ...state,
        fetching: true,
        texts: [],
      };
    case constants.MACHINE_BEGIN_COPY_MACHINE:
    case constants.PLOT_MAP_BEGIN_COPY_KEEP_OUT_AREAS:
    case constants.MACHINE_BEGIN_CREATE_MACHINE:
    case constants.MACHINE_BEGIN_DELETE_MACHINE:
    case constants.MACHINE_BEGIN_UPDATE_MACHINE:
    case constants.MACHINE_BEGIN_UPDATE_MACHINE_COORDINATES:
    case constants.MACHINE_BEGIN_UPDATE_MACHINE_STATUS:
    case constants.MACHINE_KEEPOUT_AREA_BEGIN_UPDATE_MACHINE_COORDINATES:
    case constants.PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS:
    case constants.PLOT_MAP_BEGIN_DELETE_KEEP_OUT_AREAS:
    case constants.PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS:
    case constants.PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_COORDINATES:
    case constants.PLOT_MAP_BEGIN_CREATE_KEEP_OUT_AREAS_POLYGON:
    case constants.PLOT_MAP_BEGIN_UPDATE_KEEP_OUT_AREAS_POLYGON_COORDINATES:
    case constants.PLOT_MAP_BEGIN_CREATE_ARROWS:
    case constants.PLOT_MAP_BEGIN_DELETE_ARROWS:
    case constants.PLOT_MAP_BEGIN_UPDATE_ARROWS:
    case constants.PLOT_MAP_BEGIN_UPDATE_ARROW_COORDINATES:
    case constants.PLOT_MAP_BEGIN_CREATE_TEXTS:
    case constants.PLOT_MAP_BEGIN_DELETE_TEXTS:
    case constants.PLOT_MAP_BEGIN_UPDATE_TEXTS:
    case constants.PLOT_MAP_BEGIN_UPDATE_TEXT_COORDINATE:
    case constants.PLOT_MAP_BEGIN_FETCH_MAP_IMAGE: {
      return {
        ...state,
        fetching: true,
      };
    }
    case constants.PLOT_MAP_END_FETCH_MAP_IMAGE: {
      return {
        ...state,
        fetching: false,
        mapImage: action.payload,
      };
    }
    case constants.PLOT_MAP_END_FETCH_MACHINES: {
      return {
        ...state,
        fetching: false,
        machines: action.payload,
      };
    }
    case constants.PLOT_MAP_END_FETCH_KEEPOUT_AREAS: {
      const payload = action.payload;
      return {
        ...state,
        fetching: false,
        keepOutAreas: filterAreaRectangle(payload),
        keepOutAreasPolygon: filterAreaPolygon(payload),
      };
    }
    case constants.PLOT_MAP_END_FETCH_ARROWS: {
      return {
        ...state,
        fetching: false,
        arrows: action.payload.map((arrow) => ({
          ...arrow,
          color: `#${arrow.color}`,
          coords: JSON.parse(arrow.coords),
        })),
      };
    }
    case constants.PLOT_MAP_END_FETCH_TEXTS: {
      return {
        ...state,
        fetching: false,
        texts: action.payload.map((text) => ({
          ...text,
          color: `#${text.color}`,
        })),
      };
    }
    case constants.PLOT_MAP_SET_LAYOUT_DATE: {
      localStorage.setItem("layoutDate", action.payload);

      return {
        ...state,
        layoutDate: action.payload,
      };
    }
    case constants.MACHINE_END_CREATE_MACHINE: {
      return {
        ...state,
        fetching: false,
        machines: [...state.machines, action.payload],
      };
    }
    case constants.MACHINE_END_DELETE_MACHINE: {
      return {
        ...state,
        fetching: false,
        machines: afterDeleteMachine(action.payload.machine_id, state.machines),
        keepOutAreas: afterDeleteKeepOutAreas(action.payload.keepout_area_id, state.keepOutAreas),
      };
    }
    case constants.MACHINE_END_UPDATE_MACHINE: {
      return {
        ...state,
        fetching: false,
        machines: afterUpdateMachine(action.payload, state.machines),
      };
    }
    case constants.MACHINE_END_UPDATE_MACHINE_COORDINATES: {
      return {
        ...state,
        fetching: false,
        machines: afterUpdateMachineCoordinates(
          {
            machine_id: action.params.machine_id,
            x: action.params.x,
            y: action.params.y,
            timestamp: action.payload.timestamp,
          },
          state.machines
        ),
      };
    }
    case constants.MACHINE_END_UPDATE_MACHINE_STATUS: {
      return {
        ...state,
        fetching: false,
        machines: afterUpdateMachineStatus(action.payload, state.machines),
      };
    }
    case constants.MACHINE_KEEPOUT_AREA_END_UPDATE_MACHINE_COORDINATES: {
      return {
        ...state,
        fetching: false,
        machines: afterUpdateMachine(
          { ...action.payload.machine, x: action.params.machine.x, y: action.params.machine.y },
          state.machines
        ),
        keepOutAreas: afterUpdateKeepOutAreas(
          { ...action.payload.keepOutArea, x: action.params.keepout_area.x, y: action.params.keepout_area.y },
          state.keepOutAreas
        ),
      };
    }

    case constants.PLOT_MAP_END_CREATE_KEEP_OUT_AREAS: {
      return {
        ...state,
        fetching: false,
        machines: afterCreateKeepOutAreas(action.payload, state.machines),
        keepOutAreas: [...state.keepOutAreas, action.payload],
      };
    }
    case constants.PLOT_MAP_END_CREATE_KEEP_OUT_AREAS_POLYGON: {
      return {
        ...state,
        fetching: false,
        keepOutAreasPolygon: [...state.keepOutAreasPolygon, action.payload],
      };
    }
    case constants.PLOT_MAP_ADD_WIP_KEEP_OUT_AREA_POLYGON: {
      return { ...state, wipKeepOutAreaPolygon: action.payload };
    }
    case constants.PLOT_MAP_CHANGE_WIP_KEEP_OUT_AREA_POLYGON_COMPANY: {
      return { ...state, wipKeepOutAreaPolygon: { ...state.wipKeepOutAreaPolygon, ...action.payload } };
    }
    case constants.PLOT_MAP_REMOVE_WIP_KEEP_OUT_AREA_POLYGON: {
      return { ...state, wipKeepOutAreaPolygon: undefined };
    }
    case constants.PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_POLYGON_COORDINATES: {
      const params: UpdateCustomKeepOutAreasCoordinatesParams = action.params;
      const payload: UpdateKeepOutAreasCoordinatesResponse = action.payload;

      const updated = state.keepOutAreasPolygon.map((area) => {
        if (area.keepout_area_id === params.keepout_area_id) {
          return {
            ...area,
            coords: params.coords,
            timestamp: payload.timestamp,
          };
        }
        return area;
      });

      return {
        ...state,
        fetching: false,
        keepOutAreasPolygon: updated,
      };
    }
    case constants.PLOT_MAP_END_DELETE_KEEP_OUT_AREAS: {
      return {
        ...state,
        fetching: false,
        machines: afterDeleteKeepOutAreasWithMachine(action.payload.machine_id, state.machines),
        keepOutAreas: afterDeleteKeepOutAreas(action.payload.keepout_area_id, state.keepOutAreas),
        keepOutAreasPolygon: afterDeleteKeepOutAreas(action.payload.keepout_area_id, state.keepOutAreasPolygon),
      };
    }
    case constants.PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS: {
      return {
        ...state,
        fetching: false,
        keepOutAreas: afterUpdateKeepOutAreas(action.payload, state.keepOutAreas),
        keepOutAreasPolygon: afterUpdateKeepOutAreas(action.payload, state.keepOutAreasPolygon),
      };
    }
    case constants.PLOT_MAP_END_UPDATE_KEEP_OUT_AREAS_COORDINATES: {
      return {
        ...state,
        fetching: false,
        keepOutAreas: afterUpdateKeepOutAreasCoordinates(
          {
            keepout_area_id: action.params.keepout_area_id,
            x: action.params.x,
            y: action.params.y,
            w: action.params.w,
            h: action.params.h,
            timestamp: action.payload.timestamp,
          },
          state.keepOutAreas
        ),
      };
    }
    // fall-through
    case constants.MACHINE_END_COPY_MACHINE:
    case constants.PLOT_MAP_END_COPY_KEEP_OUT_AREAS:
    case constants.APP_SHOW_ERROR: {
      return {
        ...state,
        fetching: false,
      };
    }
    // Arrows
    case constants.PLOT_MAP_END_CREATE_ARROWS: {
      return {
        ...state,
        fetching: false,
        arrows: [...state.arrows, action.payload],
      };
    }
    case constants.PLOT_MAP_END_DELETE_ARROWS: {
      return {
        ...state,
        fetching: false,
        arrows: state.arrows.filter((v) => v.arrow_id !== action.payload.arrow_id),
      };
    }
    case constants.PLOT_MAP_END_UPDATE_ARROWS: {
      return {
        ...state,
        fetching: false,
        arrows: state.arrows.map((arrow) => ({
          ...arrow,
          ...(arrow.arrow_id === action.payload.arrow_id ? action.payload : {}),
        })),
      };
    }
    case constants.PLOT_MAP_END_UPDATE_ARROW_COORDINATES: {
      return {
        ...state,
        fetching: false,
        arrows: state.arrows.map((arrow) => ({
          ...arrow,
          ...(arrow.arrow_id === action.payload.arrow_id ? action.payload : {}),
        })),
      };
    }
    case constants.PLOT_MAP_END_COPY_ARROWS: {
      return {
        ...state,
        fetching: false,
      };
    }
    // Texts
    case constants.PLOT_MAP_END_CREATE_TEXTS: {
      return {
        ...state,
        fetching: false,
        texts: [...state.texts, action.payload],
      };
    }
    case constants.PLOT_MAP_END_DELETE_TEXTS: {
      return {
        ...state,
        fetching: false,
        texts: state.texts.filter((v) => v.freetext_id !== action.payload.freetext_id),
      };
    }
    case constants.PLOT_MAP_END_UPDATE_TEXTS: {
      return {
        ...state,
        fetching: false,
        texts: state.texts.map((text) => ({
          ...text,
          ...(text.freetext_id === action.payload.freetext_id ? action.payload : {}),
        })),
      };
    }
    case constants.PLOT_MAP_END_UPDATE_TEXT_COORDINATE: {
      return {
        ...state,
        fetching: false,
        texts: state.texts.map((text) => ({
          ...text,
          ...(text.freetext_id === action.payload.freetext_id ? action.payload : {}),
        })),
      };
    }
    case constants.PLOT_MAP_END_COPY_TEXTS: {
      return {
        ...state,
        fetching: false,
      };
    }
    default:
      return state;
  }
};
