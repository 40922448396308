import * as constants from "@/constants";
import environments from "@/environments";

const convertLayoutDate = (layout_date: Date) => {
  return `${layout_date.getFullYear()}-${(layout_date.getMonth() + 1).toString().padStart(2, "0")}-${layout_date
    .getDate()
    .toString()
    .padStart(2, "0")}`;
};

export const selectPlotPlan = (plot_plan_id: string) => {
  return {
    type: constants.PLOT_MAP_SELECT_PLOT_PLAN,
    payload: plot_plan_id,
  };
};

export const fetchMapImage = (map_image_id: number) => {
  const endpoint = `${environments.API_BASE_URI}/map_images/files/${map_image_id}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_FETCH_MAP_IMAGE,
        };
      },
      success: (response) => {
        return {
          type: constants.PLOT_MAP_END_FETCH_MAP_IMAGE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchMachines = (plot_plan_id: number, layout_date: Date) => {
  const endpoint = `${environments.API_BASE_URI}/plot_plans/${plot_plan_id}/machines?layout_date=${convertLayoutDate(
    layout_date
  )}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_FETCH_MACHINES,
        };
      },
      success: (response) => {
        return {
          type: constants.PLOT_MAP_END_FETCH_MACHINES,
          payload: response.machines,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchKeepOutAreas = (plot_plan_id: number, layout_date: Date) => {
  const endpoint = `${
    environments.API_BASE_URI
  }/plot_plans/${plot_plan_id}/keepout_areas?layout_date=${convertLayoutDate(layout_date)}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_FETCH_KEEPOUT_AREAS,
        };
      },
      success: (response) => {
        return {
          type: constants.PLOT_MAP_END_FETCH_KEEPOUT_AREAS,
          payload: response.keepout_areas ?? [],
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchArrows = (plot_plan_id: number, layout_date: Date) => {
  const endpoint = `${environments.API_BASE_URI}/plot_plans/${plot_plan_id}/arrows?layout_date=${convertLayoutDate(
    layout_date
  )}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_FETCH_ARROWS,
        };
      },
      success: (response) => {
        return {
          type: constants.PLOT_MAP_END_FETCH_ARROWS,
          payload: response.arrows,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchTexts = (plot_plan_id: number, layout_date: Date) => {
  const endpoint = `${environments.API_BASE_URI}/plot_plans/${plot_plan_id}/freetexts?layout_date=${convertLayoutDate(
    layout_date
  )}`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.PLOT_MAP_BEGIN_FETCH_TEXTS,
        };
      },
      success: (response) => {
        return {
          type: constants.PLOT_MAP_END_FETCH_TEXTS,
          payload: response.freetexts,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const setLayoutDate = (layout_date: Date) => {
  return {
    type: constants.PLOT_MAP_SET_LAYOUT_DATE,
    payload: layout_date,
  };
};
